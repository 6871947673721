import React, {Fragment} from 'react';
import {api} from '../../api';

import lang from "@/lang/se.json";
export const PackingSortProduct = (props) => {

  const orderRow = props.orderRow;
  const object = JSON.parse(orderRow.object);
  const measure = ( typeof object.diameter !== 'undefined' && object.diameter > 0 ? object.diameter : object.width+'x'+object.height );
  const attach_method =  ( orderRow.field3 !== null && orderRow.field3.length > 0 ? orderRow.field3.replace('<br/>','') : 'Inga hål, ingen tejp' );


  return (
    <div className="sorted-product">
      <div>
        <div><b>{ orderRow.field1 }</b> ({ lang[object.translation_tag] }) <span style={{ marginLeft:5 }}><b>Antal:</b> { props.quantity }</span></div>

        <span className="sorted-product-prop">  { measure } mm </span>
        <span className="sorted-product-prop">{ orderRow.field4.replace('<br/>',' ') }</span>
        <span className="sorted-product-prop">{ attach_method }</span>
        <span className="sorted-product-prop">{ props.rowQuantity } rad(er)</span>

      </div>
      { props.children }
    </div>
  );
};

export const PackingSortArticle = (props) => {

  const orderRow = props.orderRow;
  const object = JSON.parse(orderRow.object);
//  let rows = JSON.parse(object.textrows);

  return (
    <div className="sorted-product">
      <div>
        {
          ( object !== undefined && object !== null ) &&
          <Fragment>
          <div><img  alt={ object.imagepath } style={{ maxWidth: 200, maxHeight:200 }} src={ api.root + '' + object.imagepath } /></div>
          <div><b>{ orderRow.field1 }</b> ({ lang[object.entity] })</div>
          <div><b>{ lang['label_description'] }: </b>{ object.info }</div>
          </Fragment>
        }

        <div><b>{ lang['label_quantity'] }:</b> { props.quantity }</div>
      </div>
      { props.children }
    </div>
  );
}

export const PackingSortShopItem = (props) => {

  const orderRow = props.orderRow;
  const object = JSON.parse(orderRow.object);

  return (
    <div className="sorted-product">
     
      <div><b>{ orderRow.field1 }</b> ({ lang[orderRow.entity] })</div>
      <div><img style={{ maxWidth: 200, maxHeight:200 }} src={ api.root + '' + object.imagepath } alt={ object.imagepath }/></div>
      <div><b>{ lang['label_description'] }: </b>{ object.description }</div>
      <div><b>{ lang['label_quantity'] }:</b>{ orderRow.quantity }</div>
    </div>
  );
}

export const PackingSortAccessory = (props) => {

  const orderRow = props.orderRow;
  const object = JSON.parse(orderRow.object);

  return (
    <div className="sorted-product">
      <div><b>{ orderRow.field1 }</b> ({ lang[orderRow.entity] })</div>
      <div><img style={{ maxWidth: 200, maxHeight:200 }} src={ api.root + '' + object.imagepath } alt={ object.imagepath }/></div>
      <div><b>{ lang['label_description'] }: </b>{ object.description }</div>
      <div><b>{ lang['label_quantity'] }:</b>{ orderRow.quantity }</div>
    </div>
  );
}

export const PackingProductRowList = (props) => {
  const items = props.items;
  return (

      items.map( (columns,i) => {
          const emptyColumns = columns.filter( t => t.length === 0 );
          const isEmpty = ( emptyColumns.length === columns.length ? true : false );
          return (
            <ul className="sorted-product-rows">
            {
              !isEmpty &&
              columns.map( (text,j) => {
                return <li key={j} className="sorted-product-row">{ text }</li>;
              })
            }
            {
              isEmpty && <li key={i} className="sorted-product-row">[ {lang['no_textrows_filled_out'] } ]</li>
            }
            </ul>
          );
        })

  );
};
