import React from 'react';
import {OrderItem} from '../OrderItem.jsx';
import {OrderProject} from '../OrderProject.jsx';

import lang from "@/lang/se.json";

export const OrderRowTable = props => {

  let order = props.object;

  let projectContent;
  let noProjectContent;
  if( typeof order.projects !== 'undefined' && order.projects.length > 0 ){

    projectContent = order.projects.map( (project, i ) => {
      return ( <OrderProject key={"project_"+project.id} order={order} name={project.name} editable={false} rows={order.rows} open={true} object={project} expanded={true} /> );
    });



    const rows = getRowsWithNoProject( order );
    noProjectContent = (
        <OrderProject
          key={"project_unsorted"+order.oid}
          order={order}
          name={lang['label_accessories_and_unsorted']}
          editable={false}
          open={true}
          rows={rows}
          expanded={true}
        />
    );



  } else {

    let rows = order.rows;
    if( rows.length > 0 ){

      noProjectContent = rows.map( ( row, i ) => {
        if( row.deleted === true ) return false;
        return ( <OrderItem key={i} order={order} object={row} rows={rows} opened={ false } editable={false}/> );
      });


    } else {
      noProjectContent = <tr><td colSpan="10">{ lang['order_is_empty'] }</td></tr>;
    }
  }





  return (
    <table className="table customer-order" id={order.oid+"_order_rows"}>
      <thead>
        <tr>
          <th>Produkt</th>
          <th>Rader</th>
          <th>Storlek</th>
          <th>Fästmetod</th>
          <th>Färg</th>
          <th >ápris</th>
          <th >Antal</th>
          <th className="number">Pris</th>
        </tr>
      </thead>
      <tbody id={"order-rows"+order.oid}>
        { projectContent }
        { noProjectContent }
      </tbody>
    </table>
  )
}

const getRowsWithNoProject = function( order ){

  const rows = order.rows.filter( r => r.projectId === null || r.projectId <= 0 );
  return rows;
}
