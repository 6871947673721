import React, { Component } from 'react';
//import $ from 'jquery';
import {DataList} from './DataList.jsx'
import {api} from './api';

import lang from "@/lang/se.json";


export const stickerFormAttributes = {
  "name"            : { "type" : "text", "label" : "label_name" },
  "description"     : { "type" : "textarea", "label" : "label_description" },
  "price"           : { "type" : "text", "label" : "label_price" },
  "imagelink"       : { "type" : "text", "label" : "label_image_link", "disabled" : true , "read_only" : true  },
  "featured"        : { "type" : "checkbox", "label" : "label_featured" }
};

export const StickerFormParams = {
  module    : 'Sticker',
  formModule: 'StickerFormModule',
  view      : 'StickerView',
  iconSrc   : api.Sticker.iconSrc,
  api_query : api.Sticker.read,
  titleColumns: ['name'],
  attributes : stickerFormAttributes
};

export class StickerModule extends Component{

  constructor( props ){
    super(props);

    this.state = {
      tableProps    : {
        idColumn        : 'id',
        betweenColumn   : 'id',
        tableColumns  : ['id','name','description','price'],
        searchFields  : ['name','description','id'],
        sortColumn    : 1,
        sortDirection : 'asc',
        itemsPerPage  : 500,
        api_query     : api.Sticker.dataList,
        start         : 0,
        length        : 500

      },
      name            : 'stickers',
      module_query  : window.location.pathname.substring(1).split('/'),

    };

  }

  handleCreate = async (e) => {

    const response = await api.send( 'Sticker', api.Sticker.new );
    this.props.openWindow( response.data.id, StickerFormParams );
  }

  openWindow = ( objectId ) => {
    let params = { ...StickerFormParams, orgWidth: 700, orgHeight: 440 };
    this.props.openWindow( objectId, params );
  }

  render(){

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12" style={{ maxWidth:1200 }}>
            <div id="moduleContent">
              <h3> { lang[this.state.name] } </h3>
              <DataList
                onCreate={this.handleCreate}
                tableProps={ this.state.tableProps }
                openWindow={this.openWindow}
                prepareEditableAttributes={this.props.prepareEditableAttributes}
                formParams={StickerFormParams}
                onChange={this.props.onChange}
              />
            </div>
          </div>
        </div>
      </div>

    )

  }
}
