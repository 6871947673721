import React, { Fragment, Component, useState, useEffect } from 'react';
import { api } from '@/modules/api';
import { sanitizeDomId } from '@/utils/objectSanitizer';
import {Buffer} from 'buffer'
import lang from "@/lang/se.json";;
import path from 'path';
import JSZip from 'jszip';
import { downloadFile } from 'react-file-download';
import jsPDF from 'jspdf';
import SVGtoPDF from 'svg-to-pdfkit';
import { getRootDir } from '@/config';
import PDFDocument from 'pdfkit';
import fs from 'fs'

const fontLoader = {
    loadFont: async (fontUrl) => {
      try {
        const response = await fetch(fontUrl);
        if (!response.ok) {
          throw new Error(`Failed to load font from ${fontUrl}: ${response.statusText}`);
        }
        const fileBuffer = await response.arrayBuffer();
        return fileBuffer;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
};

export function SVGToPDFConverter({ svgPages, label }){

    
    const [ pages, setPages ] = useState( Object.values( svgPages ) );
    const file_quantity = svgPages.length || 0;
    const [ pdfFiles, setPdfFiles ] = useState( [] );


    useEffect( () => {
        const bootstrap = async () => {
            let sheets = [ ...pages ];
            const zip = new JSZip();
            if( sheets.length > 0 ){
                sheets.map( async (sheet,i) => {
                    
                    const j = i+1;
                    const color = sanitizeDomId( sheet.color );
                    const domId = "s_"+label+'-'+sheet.material.replaceAll('_','-')+'-'+color+'-'+j;
                    const fileName =  domId;
                   // const base64doc = Buffer.from( decodeURIComponent( encodeURIComponent( document.querySelector('#'+domId).outerHTML ) ) );
                    //console.log( sheet );
                   // console.log(' url ', '.src/assets' );
                    //const files = fs.readdirSync('/src/assets/');
                    const buff = await fetch( '@/assets/fonts/GravurCondensed-Regular.ttf' );
                    console.log(' files ', buff );
                    /*
                    console.log( await fs.readFileSync( './src/assets/fonts/GravurCondensed-Regular.ttf' ) );
                    const doc = new PDFDocument({
                        font: path.join('@/assets/fonts/GravurCondensed-Regular.ttf')
                    });
                    const base64doc = new Blob([decodeURIComponent(encodeURIComponent(document.querySelector('#'+domId).outerHTML))], { type: 'image/svg+xml' });
                    SVGtoPDF(doc, base64doc, 0, 0, { width: sheet.maxWidth, height: sheet.maxHeight } );
                    //console.log(' dir name ', __dirname );
                   // const filePath = path.join(__dirname, 'GravurCondensed-Regular.ttf');
                    fs.readFile( filePath );
                    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
                    pdfDocGenerator.getStream((document) => {
                        zip.file( domId+'-ark-'+j+'.pdf', document, { binary: true } );
                    });
                    
                        // ...
                  
                    */
                });
            
        
                //const response = await api.requestPDFFiles( fd )
                //
                /*
                console.log( 'zip ', zip );
                const blob = await zip.generateAsync({type:"blob"})
                
                   
                   
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = label + '.zip';
                    a.click();
               
                */
        
            }
        }

        bootstrap();
 
    }, [ pages ] );


    
    
    if ( pdfFiles?.length <= 0 ){
        return (
            <div className="list-group">
                <li className="list-group-item list-group-item-info">
                    <div className="d-flex w-100 justify-content-between">
                        <h5 className="mb-1">{ lang.pdf_converter }</h5>
                    </div>
                    
                </li>
                <li className="list-group-item list-group-item-action">
                    <i className="fa fa-cog fa-spin fa-1x fa-fw"></i>
                    <span> { lang.processing_files } ...</span>
                </li>
            </div>
        ); 
    }


    let filesHtml = pdfFiles.map( (file,i) => {
        return ( 
            <li className="list-group-item list-group-item-action" key={i}>
                <p className="mb-1">
                    <a href={file.url} download target="_blank"><small>{file.label}</small></a>
                </p>
            </li>
        );
    });

    return (
        <div className="list-group">
            <li className="list-group-item list-group-item-info">
                <div className="d-flex w-100 justify-content-between">
                    <h5 className="mb-1">PDF Converter</h5>
                </div>
            </li>
            { filesHtml }
        </div>
    );

        
    
}