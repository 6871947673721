import React from 'react';
import {uniqId} from '../uniqid';
import {api} from './api';
//import ComboSelect from 'react-combo-select';
import $ from 'jquery';
import isJson from '@/utils/isJson';
import lang from "@/lang/se.json";;


export class ObjectForm extends React.Component{

  constructor( props ){
    super( props );

    this.state = {
      attributes  : props.attributes,
      object      : props.object,
      changes     : []

    };

  }

  toggleDisable( e, domId ){
    let element = $('#'+domId);

    if( $(element).attr('disabled') ){
      $(element).attr('disabled', false );
      $(e.target).removeClass('fa-unlock').addClass('fa-lock');
    }
    else {
      $(element).attr('disabled', true );
      $(e.target).removeClass('fa-lock').addClass('fa-unlock');
    }
  }

  deleteObject( module, id ){
    let self = this;

    api.send(
      module,
      api[module].delete +"/"+id,
      false,
      {
        type: "GET",
        beforeSend: function (){
          if(window.confirm( lang['are_you_sure_you_want_to_delete'])){
            return true;
          }
          return false;
        },
        success: function( response ){

          if( typeof self.props.onDelete !== 'undefined' )
            self.props.onDelete();

        }
      }
    );




  }

  /**
   * loops through all the forms inputs and sets all the changed properties to an array [changes] and {properties}
   * the [changes] array is forwarded to the component state for parent to update
   * the {properties} object will be sent to the api request to be saved in the api database
   **/
  handleSaveToAPI = async ( e, formId ) => {
    let properties = {};

    let inputs = $('#'+formId).find(':input');
    let object = { ...this.state.object };

    let changes = {};
    let self = this;
    
    $.each(inputs, function(i,el){
      let property = el;

      if( $(el).is(':checkbox')) {
        el.value = ( $(el).is(':checked') ? 1 : 0 );
      }

      if( ( self.state.object[property.name] === 'undefined') && el.name.length === 0 ) return false;

      if( el.value !== self.state.object[property.name] ){
        
          properties[el.name] = el.value;
          object[el.name] = el.value;
          if( typeof self.state.object[property.name] !== 'undefined' ){
            let change = { old: self.state.object[property.name], new: el.value }
            changes[el.name] = change;

          }
      }


    });

    /**
     * This block updates all elements with the class of [Module+object.id] which are set
     * in other windows/components, Theese are usually a link or element with onclick that opens the object window/component
     * when you later save the component, this block will look for the element class specified by the referring element ( link/onclick )
     * eg. <span onClick={ this.openWindow ...... } class=" Employee22" data-referensprops="fnamn,enamn" >  [fnamn] [enamn] </span>
     * this is used as an visual update only, since the windows still get their data and object from the server and the refreshing from the server
     * for all these affected objects would take a lot of resources.
     */
    let domClass = this.props.module+object.id;
    let domElements = $('#windowWrapper').find('.'+domClass);

    $.each( domElements , function(i,el){
      let props = $(el).data('referensprops').split(",");
      for(let j in props ){
        props[j] = object[props[j]];
      }
      $(el).html( props.join(' ') );

    });
    if( await api.saveObject( this.state.object.id, properties, this.props.module ) ) {
      this.setState({ object });
      this.props.onChange( object, changes );

    }


  }

  br2nl(str) {
  //  console.log(  'is json ', isJson( str ), str );
  //  if( !isJson( str ) && typeof str === 'string' )
  //      return str.replace(/<br\s*\/?>/mg,"\n");
    return str;
  }

  renderInputType (  attribute, key ){ 
  //  let inputHtml = '';
    let type = attribute.type;
    let inputDomId = this.props.module+'_'+type+'_'+attribute.name+'_'+uniqId();

    if( typeof attribute.data === 'undefined' || attribute.data === null )
      attribute.data = '';

    if( type === 'checkbox' && attribute.data.length === 0)
      attribute.data = false;

    const lockStyle = { marginTop:7, marginLeft: 4 };
    const helperText = attribute?.helper?.text || '';

    switch( type ){
      default:
      case 'text' :

        return (
            <>
                <div key={"text"+inputDomId + key} className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                    <span className="input-group-text" id="username-addon1">{ attribute.label }</span>
                    </div>
                    <input disabled={ attribute.disabled ? true : false } tabIndex={this.props.tabIndex+key} onKeyUp={this.handleInput} type="text" id={ inputDomId } name={attribute.name} data-name={ attribute.name } defaultValue={ attribute.data } className="form-control input" placeholder={ attribute.label } aria-label={attribute.name} />
                    { ( attribute.disabled === true && typeof attribute.read_only === 'undefined' ) && <i onClick={(e) => this.toggleDisable( e, inputDomId )} title={ lang['toggle_enabled_and_disabled_field']} className="fas fa-unlock toggleDisable" style={lockStyle} /> }
                </div>
                { attribute?.helper?.text && <div class="mb-3"><small>{ helperText }</small></div> }
            </>

        );

      case 'hidden':

        return (
            <>
                <input key={"hidden"+key} type="hidden" id={ inputDomId + key } name={attribute.name} data-name={ attribute.name } defaultValue={ attribute.data } />
                { attribute?.helper?.text && <div class="mb-3"><small>{ helperText }</small></div> }
            </>
        );

      case 'radio' :

        break;

      case 'number' :
        let step = attribute.step || 1;
        return (
            <>
                <div key={"number"+inputDomId + key} className="input-group input-group-sm mb-3">
                    <div className="input-group-prepend">
                    <span className="input-group-text" id="username-addon1">{ attribute.label }</span>
                    </div>
                    <input disabled={ attribute.disabled ? true : false } tabIndex={this.props.tabIndex+key} type="number" step={step} min="0" id={ inputDomId } name={attribute.name} data-name={ attribute.name } defaultValue={ attribute.data } className="form-control input" placeholder={ attribute.label } aria-label={attribute.name} />
                    { ( attribute.disabled === true && typeof attribute.read_only === 'undefined' )  && <i onClick={(e) => this.toggleDisable( e, inputDomId )} title={ lang['toggle_enabled_and_disabled_field']} className="fas fa-unlock toggleDisable" style={lockStyle} /> }
                </div>
                { attribute?.helper?.text && <div class="mb-3"><small>{ helperText }</small></div> }
            </>
          
        );


      case 'textarea' :
        let height = ( typeof attribute.height !== 'undefined' ? { height: attribute.height } : { height: 'auto' });
        if( typeof attribute.data === 'object' )
            attribute.data = JSON.stringify( attribute.data );
        return(
            <>
                <div key={"area"+inputDomId + key} className="input-group input-group-sm mb-3">
                    <div className="input-group-prepend">
                    <span className="input-group-text" id="username-addon1">{ attribute.label }</span>
                    </div>
                    <textarea disabled={ attribute.disabled === true ? true : false } style={ height }  tabIndex={this.props.tabIndex+key} id={ inputDomId } name={attribute.name} className="form-control input" data-name={ attribute.name } placeholder={ attribute.label } aria-label={attribute.name} defaultValue={ this.br2nl(attribute.data) } />
                    { ( attribute.disabled === true && typeof attribute.read_only === 'undefined' ) && <i onClick={(e) => this.toggleDisable( e, inputDomId )} title={ lang['toggle_enabled_and_disabled_field']} className="fas fa-unlock toggleDisable" style={lockStyle} /> }
                </div>
                { attribute?.helper?.text && <div class="mb-3"><small>{ helperText }</small></div> }
            </>
        );

      case 'checkbox' :
        return(
          <div key={"check"+inputDomId + key} className="form-check">
            <input
              className="form-check-input input"
              tabIndex={this.props.tabIndex+key}
              type="checkbox"
              onClick={this.handleClick}
              data-name={ attribute.name }
              defaultChecked={ (attribute.data === true || parseInt(attribute.data) === 1 )  ? true : false }
              id={inputDomId}
              name={attribute.name}
            />
            <label className="form-check-label" htmlFor="doInvoicePool">
              { attribute.label }
            </label>
            { attribute?.helper?.text && <i className="ml-1 fa fa-question-circle" title={ helperText } /> }
          </div>
        );

      case 'select' :

        if( typeof attribute.selection === 'undefined' || attribute.selection.length === 0 ) return 'selection not defined';
        return (
          <div key={"select"+inputDomId + key} className="input-group input-group-sm mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="username-addon1">{ attribute.label }</span>
            </div>
            <select
              disabled={ attribute.disabled ? true : false }
              tabIndex={this.props.tabIndex+key}
              id={ inputDomId }
              name={attribute.name}
              data-name={ attribute.name }
              defaultValue={ attribute.data }
              className="form-control input"
              placeholder={ attribute.label }
              aria-label={attribute.name}
            >
            {
              attribute.selection.map( ( item, j ) => {

                if( typeof item === 'string' ){
                  return (
                    <option key={j} value={item}>{ lang[item] } </option>
                  )

                } else {
                  return (
                    <option key={j} value={item.value}> {item.label} </option>
                  );
                }
              })
            }
            </select>
            { ( attribute.disabled === true && typeof attribute.read_only === 'undefined' ) && <i onClick={(e) => this.toggleDisable( e, inputDomId )} title={ lang['toggle_enabled_and_disabled_field']} className="fas fa-unlock toggleDisable" style={lockStyle} /> }
            { attribute?.helper?.text && <div class="mb-3"><small>{ helperText }</small></div> }
          </div>
        );
    }

    return '';

  }

    hasOldTemplate(){
        const obj = this.state.object;
        if( ( obj.data !== null || obj?.data?.length > 0 ) && ( obj.object === null || obj?.object?.length === 0 ) )
            return true;
        return false;
    }

  renderRemove( module, id ) {

    return (
      <input
        type="button"
        tabIndex={this.props.tabIndex+101}
        className="btn btn-danger pull-right"
        onClick={ (e) => this.deleteObject( module, id )}
        value={ lang['label_delete'] }
      />

    );

  }

   
  render(){

//    if( typeof this.props.attributes === 'undefined' )
//      return '';


    let attributes = this.props.attributes;
    let parent = this.props.parent;
    let attributeHtml = false;
    if( typeof attributes !== 'undefined' && attributes.length > 0 ){
      attributeHtml = attributes.map( ( attribute, i ) => {

        if(
          typeof parent !== 'undefined' &&
          typeof parent.key !== 'undefined' &&
          typeof parent.data !== 'undefined'
         ){
          if( attribute.name === parent.key ){
            attribute.data = parent.data;

          }
        }
        return this.renderInputType( attribute, i );

      });
    }
    let formId = "form_"+this.props.module+"_"+this.state.object.id;
    let submitId = "submit_"+this.props.module+"_"+this.state.object.id;

    let value = ( (typeof this.state.object === 'undefined' || this.state.object.id > 0) ? lang['save'] : lang['add'] );
    let submit = <div style={{ marginTop: 30 }}><input tabIndex={this.props.tabIndex+100} type="button" id={submitId} onClick={(e) => this.handleSaveToAPI(e, formId )} className="btn btn-success pull-left" value={ value } /></div>
    
    return (
      <form className="form" id={formId} >
        { this.props.lastUpdated && this.hasOldTemplate() && this.props.lastUpdated }
        <div className="small">{ typeof this.props.objectId !== 'undefined' && lang['label_object_id'] + ' :: '+ this.props.objectId }</div>
        
        { attributeHtml !== false && attributeHtml }
        { attributeHtml !== false && submit }
        { typeof this.props.children !== 'undefined' && this.props.children }
        { attributeHtml !== false && this.renderRemove( this.props.module, this.props.objectId )}
        <div style={{ clear:'both', marginBottom: 0 }} />
      </form>
    );
  }

}
