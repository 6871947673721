import React from 'react';
import {api} from './api';
import {windowManager} from '../windowManager';
import {ObjectForm} from './ObjectForm.jsx';
import $ from 'jquery';
import {employeeFormAttributes} from './EmployeeModule';
import {accountingNumbers} from '../config.js';

import lang from "@/lang/se.json";

export class EmployeeFormModule extends React.Component{

  constructor( props ){
    super( props )

    this.state = {
      object : {},
      isLoaded: false,
      orders : []
    };
    this.wm = new windowManager();

  }


  onChange = ( object, changes ) => {
    this.setState({ object : object });
    this.props.onChange( object, changes );
  }

    async fetchObject(){

        const response = await api.send( 'Employee', 'employee/'+this.props.objectId, {}, { type: 'GET' } );
        
        if( response.code === 200 ){
            let object = response.data;
            let time = new Date().getTime();
            
            this.props.updateTitle( this.props.makeWindowTitle( object , ['fnamn','enamn']) );
            this.setState({ isUpdated: time, object: object, isLoaded: true });
        
            return object;
        }
        return null;
    }

    async componentDidMount(){
        const object = await this.fetchObject();

        const response = await api.send('Employee', 'employee/orders/'+ object.id, {}, { type:'GET'});
        if( response.code === 200 ){
            this.setState({ orders: response.data });
        }
        
    }

  updateInput ( e , property ){

    let object = Object.assign({}, this.state.object );
    object[property] = e.target.value;
    let win = this.wm.getWindow( this.props.windowId );
    win.object = object;
    win.title = object.name;
    this.wm.setWindow( win, this.props.windowId );
    this.props.onChange( object );
    this.setState({ object });

  }
  deletePhone = async ( id,i ) => {
    const object = { ...this.state.object };
    const phones = [ ...object.phoneNumbers ];
    if( typeof id === 'undefined'){
      phones.splice(i,1);
      object.phoneNumbers = phones;
      this.setState({object});

      return true;
    }

    const response = await api.send('Employee', 'employee/deletePhone/'+ this.state.object.id, { id } );
    if(response.code === 200 ){

      phones.splice(i,1);
      object.phoneNumbers = phones;
      this.setState({object});

    }
  }

  addPhone = () => {
    const object = { ...this.state.object };
    const phones = [ ...object.phoneNumbers ];
    phones.push({
      telnr : ''
    });

    object.phoneNumbers = phones;
    this.setState({ object });
  }

  updatePhone = (e, nObject) => {
    const object = { ...this.state.object };
    const phones = [ ...object.phoneNumbers ];
    const index = phones.indexOf( nObject );

    nObject.telnr = e.target.value;

    phones[index] = nObject;
    object.phoneNumbers = phones;

    this.setState({ object });



  }

  savePhone = ( e, i ) => {
    const number = this.state.object.phoneNumbers[i];
  //  const telnr = number.telnr;
    api.send('Employee', api.Employee.savePhoneNr + '/'+ this.state.object.id, { number } );
  }

  handleDelete = () => {
    $('#'+this.props.windowId).find('.close').click();
  //  window.location.reload();
  }

  render(){
    let {object, orders} = this.state;

    if( !this.state.isLoaded ) return null;

    let attributes = this.props.prepareEditableAttributes(employeeFormAttributes, object);

    return (
      <div className="container">
        <div className="row">
          <div className="col-md-7">
            <ObjectForm
              attributes={attributes}
              tabIndex={this.props.tabIndex}
              savePath={ api.Employee.save }
              parent={this.props.parent}
              object={object}
              onChange={this.onChange}
              updateState={this.props.updateState}
              module="Employee"
              objectId={ object.id }
              onDelete={this.handleDelete}
            />
          </div>
          <div className="col-md-5 windowInfo">
            <h5>{ lang['phone_numbers'] }</h5>
            {
              typeof object.phoneNumbers !== 'undefined' &&
              object.phoneNumbers.map( (number,i) => {

                return (
                  <div key={this.props.module+"_phone_"+i} className="number">
                    <input type="text" value={ number.telnr } onChange={(e) => this.updatePhone(e,number)} onBlur={ (e) => this.savePhone(e,i)}/>
                    <i className="fas fa-times-circle text-danger ml-1" onClick={ () => this.deletePhone(number.id,i) } title={ lang.delete_phone }/>
                  </div>
                );
              })
            }
            <div><button className="btn btn-success btn-xs pull-right" onClick={ this.addPhone } title={ lang["add_phone"] } style={{ margin:'5px 0' }}><i className="fas fa-plus" /></button></div>

            <div style={{ clear:'both' }}>
            { (typeof object.Foretag !== 'undefined' && object.Foretag !== null ) &&
              <React.Fragment>
                <h5>{ lang.label_customer_number }: { object.Foretag.custnr }</h5>
                <div><span className="link" onClick={(e) => this.props.openWindow(e, "Customer", object.Foretag.id, ['name'])} >{ object.Foretag.name }</span></div>
              </React.Fragment>
            }
            </div>

          </div>
        </div>
        <div className="row">
          <div className="col">
            <h5>{ lang['orders'] }</h5>
            <table className="table table-striped userOrders">
              <thead>
                <tr>
                  <th>{ lang.table_columns.id }</th>
                  <th>{ lang.table_columns.time }</th>
                  <th>{ lang.label_io_number }</th>
                  <th>{ lang.table_columns.ordername }</th>
                  <th className="number">{ lang.table_columns[':totalprice']}</th>
                </tr>
              </thead>
              <tbody>
              {
                orders.length > 0 &&
                orders.map( (order,i ) => {
                  let total = ( order.totalprice > order.minimumvalue ? order.totalprice : order.minimumvalue );
                  if( total === null )
                    total = 0;
                  if( order.deleted === true || order.status === 'order_active') return '';
                  return (
                    <tr key={i} onClick={(e) => this.props.openWindow(e, "Order", order.oid, ['oid','ordername'] ) } style={{ cursor:'pointer'}}>
                      <td>{ order.oid }</td>
                      <td>{ order.time }</td>
                      <td>{ order.ionumber }</td>
                      <td>{ order.ordername }</td>
                      <td className="number">{ accountingNumbers.se( total , true) }</td>
                    </tr>
                  );
                })
              }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
};
