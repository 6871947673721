import React, { Component } from 'react';
//import $ from 'jquery';
import {DataList} from './DataList.jsx'
import {api} from './api';

import lang from "@/lang/se.json";
const selection = [
  {
    "label"     : lang['label_invoice_address'],
    "value"     : "inv"
  },
  {
    "label"     : lang['label_delivery_address'],
    "value"     : "del"
  }
];
export const addressFormAttributes = {
  "name"      : { "type" : "text", "label" : "label" },
  "company"   : { "type" : "text", "label" : "label_address_company" },
  "gata"      : { "type" : "text", "label" : "label_address" },
  "address2"  : { "type" : "text", "label" : "label_address2" },
//        "gatunr"    : { "type" : "textarea", "label" : "label_street_number" },
  "postnr"    : { "type" : "text", "label" : "label_zipcode" },
  "postort"   : { "type" : "text", "label" : "label_address_city" },
  "type"      : { "type" : "select", "label" : "label_address_type" , "selection" : selection },
  "foretagId"  : { "type" : "text", "label" : "label_company_id", "name" : "foretagId", "disabled" : true  }

}

export const addressFormParams = {
  module    : 'Address',
  formModule: 'AddressFormModule',
  view      : 'AddressView',
  iconSrc   : api.Address.iconSrc,
  api_query : api.Address.read,
  titleColumns: ['name','company'],
  attributes : addressFormAttributes
};

export class AddressModule extends Component{

  constructor( props ){
    super(props);

    this.state = {
      tableProps    : {
        idColumn        : 'id',
        tableColumns  : ['id',':custnr','name','company','gata','address2','postnr','postort'],
        childColumns   : {
          'custnr' : 'foretag.custnr'
        },
        searchFields  : ['name','company','gata','postnr','postort'],
        sortColumn    : 2,
        sortDirection : 'asc',
        api_query     : api.Address.dataList,
        start         : 0,
        length        : 100

      },
      name            : 'Addresses',
      module_query  : window.location.pathname.substring(1).split('/'),

    };

  }

  openWindow = ( objectId ) => {
    this.props.openWindow( objectId, addressFormParams );
  }

  handleCreate = async (e) => {

    const response = await api.send( 'Address', api.Address.new );
    this.props.openWindow( response.data.id, addressFormParams );
  }

  render(){

    return (
      <div className="container-fluid">

        <div className="col" style={{ maxWidth: 1000 }}>
          <div id="moduleContent">
            <h3> { lang[this.state.name] } </h3>
            <DataList
              tableProps={ this.state.tableProps }
              openWindow={this.openWindow}
              handleLogout={this.props.handleLogout}
              onCreate={this.handleCreate}
              prepareEditableAttributes={this.props.prepareEditableAttributes}
              formParams={addressFormParams}
              onChange={this.props.onChange}
              openinWindow={true}
            />
          </div>
        </div>

      </div>

    )

  }
}
