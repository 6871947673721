import React from 'react';
import {accountingNumbers} from '../../config';
import lang from "@/lang/se.json";

export const OrderTotal = ( props ) => {

  const object = props.object;
  const useMinimalValue = ( object.totalprice >= object.minimumvalue ? true : false );

  return (
    <div>
    <table className="orderTotal" style={{ float:'right', maxWidth: 300 }}>
      <tbody>
        <tr>
          <td className="label">{ lang['label_number_of_products']}</td>
          <td className="number">{ object.nrofproducts }</td>
        </tr>
        <tr>
          <td className="label">{ lang['label_price_sub_total']}</td>
          <td className="number">{ accountingNumbers.se(object.sub_total) }</td>
        </tr>
        <tr>
          <td className="label">{ lang['label_price_discount']}</td>
          <td className="number"> -{ accountingNumbers.se(object.discounts) } </td>
        </tr>
        {
          useMinimalValue === true &&
          <tr className="bordered">
            <td className="label">{ lang['label_minimum_value']}</td>
            <td className="number"> { accountingNumbers.se(object.minimumvalue, true) } </td>
          </tr>
        }
        <tr className="bordered">
          <td className="label">{ lang['label_price_ex_vat']}</td>
          <td className="number"> { accountingNumbers.se(object.totalprice, true) } </td>
        </tr>
        <tr>
          <td className="label">{ lang['label_vat']}</td>
          <td className="number">{ accountingNumbers.se(object.vat, true) }</td>
        </tr>
        <tr className="bordered">
          <td><h6 className="label">{ lang['label_price_incl_vat']}</h6></td>
          <td className="number"><h6 className="label" style={{ whiteSpace:'nowrap'}}>{ accountingNumbers.se(object.totalprice + object.vat, true) }</h6></td>
        </tr>
      </tbody>
    </table>
        </div>
  )

};
