import React, { Fragment, Component } from 'react';
//import '../../sign.css?v1';
//import ReactSVG from "react-svg";
import { componentBlocks, drillBitOffset } from './config.js';
import { colorCodes } from '../config.js';
export class Sign extends Component{

    constructor(props){
        super(props);
        this.pc = props.pixelConverter;
        this.sign = this.pc.convertSVGtoPixels( { ...props.product } );

    }

    renderStripeHoles(){
        const data = { ...this.sign };

        //drillBitOffset is the offset to compensate for the radius of the drillbit. All holes has to be offset the radius of the drillbit
        const drillBitOffsetPx = data.shape !== 'rectangular' ? this.pc.toPixel( drillBitOffset ) : 0;

        if( data.stripe_holes === 0 )
            data.stripe_holes = false;

        if( data.stripe_holes === false || data.stripe_holes === "false" || typeof data.stripe_holes === 'undefined' ) return false;

        let holeStyle = { width: this.pc.toPixel( 6 - 2 ), height: this.pc.toPixel( 2.5 - 2 ) };
        let holes = [
            { x: data.hole_distance + drillBitOffsetPx, y: data.hole_distance + drillBitOffsetPx },
            { x: data.hole_distance + drillBitOffsetPx, y: ( data.height - data.hole_distance  - holeStyle.height )  + drillBitOffsetPx },
            { x: ( data.width  - data.hole_distance - holeStyle.width ), y: data.hole_distance  + drillBitOffsetPx },
            { x: ( data.width - data.hole_distance - holeStyle.width ), y: ( data.height - data.hole_distance - holeStyle.height ) + drillBitOffsetPx }
        ];

        let x = 0;
        let y = 0;

        if( data.shape !== 'rectangular' ){
            x = this.pc.toPixel( drillBitOffset );
            y = this.pc.toPixel( drillBitOffset );
        }
        
        return (
            <Fragment>
                <g className="stripeHoles" transform={ `translate(${x},${y})` }>
                    
                    { holes.map( (hole,i) => {
                        let props = { 
                            ...hole,
                            fill: "#ff0",
                            ...holeStyle
                        };
                        return <rect { ...props } key={i}/>

                    })}
                </g>
            </Fragment>
        );

    }

    getHolePositions(){
        let { hole_quantity, hole_type, product_type, hole_distance, width, height } = this.sign;
        let holes = [];
        hole_quantity = parseInt( hole_quantity )
        if( typeof hole_type === 'undefined' )
            hole_type = '';

        // eslint-disable-next-line default-case
        switch(hole_quantity){

            case 1:
                let position = product_type == 'ValveTag' ? { cx: width/2, cy:hole_distance } : { cx: hole_distance, cy:height/2 };
                holes.push( position );
                break;
            case 2:
                holes = [
                    { cx: hole_distance, cy:height/2 },
                    { cx: ( width - hole_distance ), cy:height/2 }
                ];
                break;
            case 4:
                holes = [
                    { cx: hole_distance, cy:hole_distance},
                    { cx: hole_distance, cy: ( height - hole_distance ) },
                    { cx: ( width - hole_distance ), cy: hole_distance },
                    { cx: ( width - hole_distance ), cy: ( height - hole_distance ) }
                ];
                break;

            case 6:
                holes = [
                    { cx: hole_distance, cy:hole_distance},
                    { cx: hole_distance, cy: ( height - hole_distance ) },
                    { cx: width/2, cy:hole_distance},
                    { cx: width/2, cy: ( height - hole_distance ) },
                    { cx: ( width - hole_distance ), cy: hole_distance },
                    { cx: ( width - hole_distance ), cy: ( height - hole_distance ) }
                ];
                break;

            case 8:
                holes = [
                    { cx: hole_distance, cy: hole_distance},
                    { cx: hole_distance, cy: height/2 },
                    { cx: hole_distance, cy: ( height - hole_distance ) },
                    { cx: width/2, cy:hole_distance},
                    { cx: width/2, cy: ( height - hole_distance ) },
                    { cx: ( width - hole_distance ), cy: hole_distance },
                    { cx: ( width - hole_distance ), cy: height/2 },
                    { cx: ( width - hole_distance ), cy: ( height - hole_distance ) }
                ];
        }

        return holes;

    }

    renderHoles(){
        let sign = this.sign;
        let product = this.props.product;
        let holes = this.getHolePositions();
        let svg ='';
        if( sign.stripe_holes === true || sign.stripe_holes === "true" ){
            svg = this.renderStripeHoles();
        }
        if( sign.hole_quantity > 0 ){
            let hasMeasuredHoles = sign.standard_holes;
            let holeSize = hasMeasuredHoles ? this.pc.toPixel( product.hole_size - drillBitOffset ) : 1;
            let radius = holeSize/2;
            let fill = hasMeasuredHoles ? '#ff0' : '#0f0';
            let x = 0
            let y = 0;
            if( sign.shape !== 'rectangular' ){
                x = this.pc.toPixel( drillBitOffset );
                y = this.pc.toPixel( drillBitOffset );
            }
            //console.log( 'drillbit ', drillBitOffset, 'hasMeasuredHoles ', hasMeasuredHoles, ' holeSize ', holeSize, this.props.product.hole_size, ' drillbit px ', this.pc.toPixel( drillBitOffset ) )
            svg = (
                <Fragment>
                    <g className="holes" transform={ `translate( ${x},${y} )` }>
                        
                        { holes.map( (hole,i) => {
                            let props = { 
                                ...hole,
                                fill,
                                r: radius
                            };
                            return (<circle { ...props } key={i} />);

                        })}
                    </g>
                </Fragment>
            );
                
        }
        return svg;
          
    }

    renderBlocks( )
    {
        let sign = this.sign;
        let blocks = Object.values( { ...sign.blocks } );

        return blocks.map((b,i) => {

            let Block = componentBlocks[ b.type ];
            if( typeof Block === 'undefined' )
                return ;        
            return ( 
                <Block data={b} sign={ sign } pixelConverter={ this.pc } key={i} index={i} product={ this.props.product }/>
            );

        });
    }

     render(){
        let height= this.sign.height;
        let width = this.sign.width;
        let style = {};
        
        let rounded = {};
        //let bgcolor = colorCodes[ this.sign.background_color ];

        if( this.sign.shape !== 'rectangular' ){
            style = { strokeWidth: '0.2px', stroke:'rgb(0,0,255)' };
            width += this.pc.toPixel( 2 );
            height += this.pc.toPixel( 2 );
        }

        if( this.sign.shape === 'rounded' )
            rounded = { rx: this.sign.height/10, ry: this.sign.height/10 };
        else if ( this.sign.shape === 'oval' ){
            rounded = { rx: this.sign.width/2, ry: this.sign.height/2 };
        } else {
            rounded = {};
        }
        

        return (
            <Fragment>
                {
                    this.sign.shape !== 'rectangular' &&
                    <rect
                        style={ style }
                        width={width}
                        height={height}
                        fill={ 'none'}
                        { ...rounded }
                    />
                }
                { this.renderBlocks() }
                { this.renderHoles() }
                { 
                    this.sign.shape === 'rectangular' && 
                    <Fragment>
                        <line x1="0" y1={ height } x2={width} y2={height} strokeWidth="0.2px" stroke="red" ></line>
                        <line x1={width} y1="0" x2={width} y2={height} strokeWidth="0.2px" stroke="red"></line>
                    </Fragment>
                }
            </Fragment>
        );
    }
}