import React, { Component } from 'react';
//import $ from 'jquery';
import {DataList} from './DataList.jsx'
import {api} from './api';

import lang from "@/lang/se.json";

export const employeeFormAttributes = {
  "fnamn"          : { "type" : "text", "label" : "label_firstname" },
  "enamn"        : { "type" : "text", "label" : "label_lastname" },
  "username"      : { "type" : "text", "label" : "label_username", "disabled" : true  },
  "password"      : { "type" : "password", "label" : "label_password", "disabled" : true  },
  "password2"      : { "type" : "password", "label" : "label_password_again", "disabled" : true  },
  "email"         : { "type" : "text", "label" : "label_email", "disabled" : true  },
  "foretagId"     : { "type" : "text", "label" : "label_company_id", "disabled" : true },
  "acceptedTerms" : { "type" : "checkbox", "label" : "label_accepted_terms" }
};
export const employeeFormParams = {
  module    : 'Employee',
  formModule: "EmployeeFormModule",
  view      : "EmployeeView",
  iconSrc   : api.Employee.iconSrc,
  api_query : api.Employee.read,
  titleColumns: ['fnamn','enamn'],
  attributes: employeeFormAttributes
};
export class EmployeeModule extends Component{

  constructor( props ){
    super(props);


    this.state = {
      tableProps    : {
        idColumn  : 'id',
        tableColumns  : ['id','fnamn','enamn',':Foretag',':Foretag.orgnr','email','username'],
        childColumns  : {':Foretag' : 'company', ':Foretag.orgnr' : 'orgnr'},
        searchFields  : ['id','fnamn','enamn','email','username'],
        sortColumn    : 3,
        sortDirection : 'asc',
        api_query     : api.Employee.dataList,
        start         : 0,
        length        : 100

      },
      module_query  : window.location.pathname.substring(1).split('/'),
      name          : "Employees"
    }

  }

  openWindow = ( objectId, params ) => {

    // If a child component uses openWindow for a link, it will use the params arguments
    let wparams = ( (typeof params === 'object') ? params : employeeFormParams );
    this.props.openWindow( objectId, wparams );
  }


  render(){

    return (
      <div className="container-fluid">

        <div className="col" style={{ maxWidth: 1000 }}>
          <div id="moduleContent">
            <h3>{ lang[this.state.name] } </h3>
            <DataList
              handleLogout={this.props.handleLogout}
              tableProps={ this.state.tableProps }
              openWindow={this.openWindow}
              prepareEditableAttributes={this.props.prepareEditableAttributes}
              formParams={employeeFormParams}
              onChange={this.props.onChange}
            />
          </div>
        </div>
      </div>
    )

  }
}
