import React, { Component } from 'react';
//import $ from 'jquery';
import {DataList} from './DataList.jsx'
import {api} from './api';

import lang from "@/lang/se.json";

export const customerGroupFormAttributes = {
  "name"                    : { "type" : "text", "label" : "label_name" },
  "discount"                : { "type" : "number", "label" : "label_discount" , "step" : 5 },
  "onproducts"              : { "type" : "checkbox", "label" : "label_products" },
  "onaccessories"           : { "type" : "checkbox", "label" : "label_accessories" },
  "onstickers"              : { "type" : "checkbox", "label" : "label_stickers" },
  "onwml"                   : { "type" : "checkbox", "label" : "label_wml" },
  "onminimumvalue"          : { "type" : "checkbox", "label" : "label_minimum_value" },
  "oncablemarkerspecial"    : { "type" : "checkbox", "label" : "label_cablemarker_special" },
};

export const customerGroupFormParams = {
  module    : 'CustomerGroup',
  formModule: 'CustomerGroupFormModule',
  view      : 'CustomerGroupView',
  iconSrc   : api.CustomerGroup.iconSrc,
  api_query : api.CustomerGroup.read,
  titleColumns: ['name'],
  attributes : customerGroupFormAttributes
};

export class CustomerGroupModule extends Component{

  constructor( props ){
    super(props);

    this.state = {
      tableProps    : {
        idColumn        : 'id',
        tableColumns  : ['id','name','discount','onproducts','onaccessories','onminimumvalue', 'oncablemarkerspecial','onstickers','onwml'],
        isBool : [3,4,5,6,7,8],
        searchFields  : ['name','id','discount'],
        sortColumn    : 1,
        sortDirection : 'asc',
        api_query     : api.CustomerGroup.dataList,
        start         : 0,
        length        : 100

      },
      name            : 'label_customer_groups',
      module_query  : window.location.pathname.substring(1).split('/'),

    };

  }
  handleCreate = async (e) => {

    const response = await api.send( 'CustomerGroup', api.CustomerGroup.new );
    this.props.openWindow( response.data.id, customerGroupFormParams );
  }
  openWindow = ( objectId, formParams ) => {

    let wparams = ( (typeof formParams === 'object') ? formParams : customerGroupFormParams );
    this.props.openWindow( objectId, wparams );
  }

  render(){

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12" style={{ maxWidth:1000}} >
            <div id="moduleContent">
              <h3> { lang[this.state.name] } </h3>
              <DataList
                onCreate={this.handleCreate}
                tableProps={ this.state.tableProps }
                openWindow={this.openWindow}
                handleLogout={this.props.handleLogout}
                prepareEditableAttributes={this.props.prepareEditableAttributes}
                formParams={customerGroupFormParams}
                onChange={this.props.onChange}
                openinWindow={true}
              />
            </div>
          </div>
        </div>
      </div>

    )

  }
}
