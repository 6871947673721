import React, {Fragment} from 'react';
//import $ from 'jquery';
//import {api} from './api';
import {OrderItem} from './OrderItem.jsx';

import lang from "@/lang/se.json";;

export class OrderProject extends React.Component{

  constructor( props ){

    super( props );

    this.state = {
      data : props.object,
      isOpen : props.open
    };



  }
  handleRowClick = ( e ,state ) => {
    if( typeof this.props.handleClick !== 'undefined' )
      this.props.handleClick( e, state );

  }
  handleProjectClick = () => {
    this.setState({ isOpen: !this.state.isOpen });
  }
  onItemUpdate = ( rows ) => {
    this.props.onItemUpdate(rows);
  }
  getProjectRows(){

    let prows = [];

    if( typeof this.props.object === 'undefined' )
      return this.props.rows;

    if( typeof this.props.rows !== 'undefined' && this.props.rows.length > 0 ){
      var i;
      for( i in this.props.rows ){
        let r = this.props.rows[i];
        if( r.projectId === this.state.data.id )
          prows.push( r );
      }
    }

    return prows;
  }
  render(){

    let rows = this.getProjectRows();
    const projId = ( typeof this.props.object !== 'undefined' ? this.state.data.id : 'project_order_'+this.props.order.oid );
    let rowcontent = rows.map( (row, i ) => {
      return ( <OrderItem editable={this.props.editable} key={this.props.order.oid+'_'+i} onItemUpdate={this.onItemUpdate} order={this.props.order} object={row} rows={rows} handleClick={this.handleRowClick} opened={ false } /> );
    });
    const chevron = ( this.state.isOpen === true ? 'up' : 'down' );
    return (
      <Fragment>
        <tr onClick={() => this.handleProjectClick()}>
          <td className="projectRow" colSpan="10">
            <div className="project-row-bg project-minimizer" data-key={projId}>
              <i className={"fas fa-chevron-"+chevron}></i> { lang['label_project'] } : <span className="title-20-thin m-l-20"> {this.props.name} </span>
            </div>
          </td>
        </tr>
        { this.state.isOpen && rowcontent }
      </Fragment>
    );
/*
<div className="project-buttons btn-group">
  <button role="button" className="btn btn-xs btn-info activeProjectLbel" title="Aktivt projekt"><i className="fas fa-check-square"></i> { lang['label_active_project'] }</button>
  <button type="button" className="btn btn-xs btn-danger removeProject" title="Radera projekt från ordern. Alla produkter som ligger i projektet kommer läggas under osorterade" data-id="71"><i className="fa fa-times-circle"></i></button>
</div>
*/
  }
}
