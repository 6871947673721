import React from 'react';
import { api } from '../api';
import DOMPurify from 'dompurify';
import {accountingNumbers} from '../../config.js';
import lang from "@/lang/se.json";
export const ArticleView = (props) => {
  const object = props.object;
  let imageLink;
  if( object.imagelink.length > 0 )
    imageLink =  api.root+''+ object.imagelink;
  else {
    imageLink = '/assets/images/icons/no_image.svg';
  }
  let companiesContent;

  if( object.companies.length > 0 ){
    companiesContent = object.companies.map( (company,i) => {
      return <li key={i} className="list-item" title= { lang['label_customer_number'] +' :: '+ lang['label_company_name'] } > { company.custnr +' :: '+company.name }</li>;
    });
  } else {
    companiesContent = <li className="list-item">{ lang['article_not_connected'] }</li>;
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-12 col-md-4">
          <img style={{ maxWidth:'100%', float:'left', maxHeight:'200px', marginRight:'10px', marginBottom:10 }} src={ imageLink } alt="image_thumb"/>
        </div>
        <div className="col-sm-12 col-md-4">
          <div><span className="label">{ object.objnamn } :: { object.objtitel }</span></div>
          <div><span className="label">ID #</span>{ object.id }</div>
          <div><span className="label">{ lang['label_other_info'] }</span></div>
          <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize( object.info )}} />
          <div className="price-wrapper">
            <div><span className="label">{ lang['label_price'] }</span> <span className="price" id="price"> { accountingNumbers.se(object.price,true) } </span> </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-4">
            <h6>{ lang['is_used_by'] }</h6>
            { companiesContent }
        </div>
      </div>
    </div>

  );

};
