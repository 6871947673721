import React from 'react';
import $ from 'jquery';
import {windowManager} from '../windowManager';
import {api} from './api';
import {ObjectForm} from './ObjectForm.jsx';
import {Address} from './Address.jsx';
import {DropdownSelection} from './HtmlComponents.jsx';
import {customerFormAttributes} from './CustomerModule';

import lang from "@/lang/se.json";
function generateRandomString(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export class CustomerFormModule extends React.Component{

  constructor( props ){
    super( props )

    this.state = {
      object : props.object,
      employees : false,
      formAttributes: customerFormAttributes,
      affCode: ''
    };

    this.wm = new windowManager();

  }

  updateWindowTitle = ( e ) => {

    this.props.updateTitle( e.target.value );

  }

  onAddressUpdate(){}

  onChange = ( object, changes ) => {
    this.setState({ object : object });
    this.props.onChange( object, changes );
  }
  updateMinimumValue( e ){

    let object = Object.assign({},this.state.object);
    object.useminimumvalue = ( $(e.target).is(':checked') ? true : false );
    this.setState({ object: object });

  }

  getAddressesByType( type )
  {
    let addresses = this.state.object.addresses;

    let i;
//    let address = false;
    let selection = []
    for(i in addresses ){
      let adr = addresses[i];
      if( adr.type === type ){
        selection.push( adr );
      }
    }
    return selection;
  }
  handleDelete = () => {
    $('#'+this.props.windowId).find('.close').click();
  //  window.location.reload();
  }

  openItemWindow( objectId ){

//    let query = ( ( typeof objectId === 'undefined' || objectId === null ) ? api.Address.new :  api.Address.read );
    let type = ( objectId > 0 ? "GET" : "POST" );
    let windowParams = {
      module    : 'Address',
      iconSrc   : api.Address.iconSrc,
//      api_query : query,
      titleColumns: ['name'],
      data  : { parentId: this.state.object.id },
      parent: { key: 'foretagId', data: this.state.object.id },
      requestType: type
    };
    this.props.openWindow(false, "Address", objectId, ['name','company'], windowParams );
  }

  newUser = async ( e, object ) => {
    e.stopPropagation();
    /*
    let fid = object.id;
    let windowParams = {
      module    : 'Employee',
      data  : { parentId: this.state.object.id },
      parent: { key: 'foretagId', data: fid },
      requestType: "POST"
    };
*/
    const response = await api.send('Employee', 'employee/new', { parentId: object.id });
    let windowParams = {
      module    : 'Employee',
      data  : { parentId: this.state.object.id },
      parent: { key: 'foretagId', data: this.state.object.id },
      requestType: "GET"
    };
    this.props.openWindow(e, "Employee", response.data.id, ['fnamn','enamn'], windowParams );

  }

  onItemSelect = async ( item , parentId , edit ) => {

//    let itemId = item.id;
    if( typeof item.id === 'undefined' && edit === true && typeof edit !== 'undefined' ){
     const response = await api.send('Address', api.Address.new, { parentId: this.state.object.id });
     this.openItemWindow( response.data.id );

    } else if( item.id > 0 && edit === true ){

      this.openItemWindow( item.id );

    } else {
      let object = Object.assign({},this.state.object);
      object.ActiveInvoiceAddress = item;
      object.activeAddressId = item.id;
      this.onChange( object, [] );
      await api.saveObject( object.id, { activeAddressId:item.id }, "Customer" );

    }

  }

    async fetchObject(){
        let self = this;
    //    let url = api.api+api.Accessory.read+'/'+this.props.objectId;
    //    let order = false;

        await api.send(
        'Customer',
        'company/'+this.props.objectId,
        false,
        {
            type: 'GET',
            success : function( response ){

            let object = response.data;
    //          let time = new Date().getTime();
            self.setState({ object: object });
            //self.props.updateState();
            self.props.updateTitle( self.props.makeWindowTitle( object , ['name']) );

            }
        }
        );
    
    }

    async deleteCode(){
        let object = { ...this.state.object };
        const response = await api.send('','company/update', { 
            id: object.id,
            properties: btoa( JSON.stringify({ affiliateCode: null } ) )
        }, { type: 'POST' });

        if( response.code === 200 ){
            object.affiliateCode = null;
            this.setState( { object: object } );
        }

    }

    async generateCode(){

        this.setState({ 
            affCode: generateRandomString( 4 )+btoa( Date.now() ).replace(/=/g,'') 
        });
        
        
    }

    generatedLink(){
        let link = new URL( api.root )
        return `[${link.origin}/se/registration?ac=${this.state.object.affiliateCode}]`;
    }

  async componentDidMount(){
    await this.fetchObject();

    const response = await api.send( 'CustomerGroup', 'customerGroup/list' , false , { type: 'GET' });
    const formAttributes = { ...this.state.formAttributes };
    let groupsLoaded = false;
    if( response.code === 200 ){

      let data = response.data;

      let groups = [{ value:false, label: '--'+lang.label_choose_customer_group+'--' }, { value:false, label: '-'+lang.label_no_customer_group+'-' }];
      for(let i in data ){
        groups.push({
          value   : data[i].id,
          label   : data[i].name
        });
      }

      let groupId = this.state.object.customergroupid || null;
      formAttributes.customergroupid = { ...this.state.formAttributes.customergroupid, selection: groups, data: groupId };
      groupsLoaded = true;

    }

    // GET employees
    let employees = [];
    const response2 = await api.send('Company', 'company/listEmployees/'+this.state.object.id, false, { type:'GET'});
    if( response2.code === 200 ){
      employees = response2.data;
    }

    this.setState({ formAttributes:formAttributes, groups: groupsLoaded, employees });
  }


  getInvoiceAddress( object )
  {
    let addresses = object.addresses;

    let i;
    let address = false;
    for(i in addresses ){
      let adr = addresses[i];
      if( adr.type === 'inv' ){
        return adr;
      }
    }
    return address;
  }
  renderInvoiceAddress( object ){

    let invAaddress = (
      <Address
        object={object.ActiveInvoiceAddress}
        lang={this.props.lang}
        items={ object.addresses }
        parentId={object.id}
        openWindow={this.props.openWindow}
        parent={object}
        displayInline={true}
        type='inv'
        companyName={ object.name }
      />
    );

    let selection = this.getAddressesByType( 'inv' );

    return (
      <DropdownSelection
        content={ invAaddress }
        label={ "invoiceAddresses" }
        title={ lang['label_invoice_address'] }
        selection={ selection }
        selectable={ true }
        parent={ object }
        editable={ true }
        addable={ true }
        onSelect={ this.onItemSelect }
        filter={this.filter}
        filterKey={ this.filterKey }
        filterValue={ this.filterValue }

      />
    );


  }

  renderDeliveryAddresses( object ){

    let selection = this.getAddressesByType( 'del' );

    return (
      <DropdownSelection
        title={ lang['label_delivery_addresses'] }
        label={ "deliveyAddresses" }
        selection={ selection }
        parent={ object }
        editable={true}
        addable={true}
        onSelect={ this.onItemSelect }
        filter={this.filter}
        filterKey={ this.filterKey }
        filterValue={ this.filterValue }

      />
    );
  }
  renderEmployees( object )
  {
    let {employees} = this.state;
    let emps = [];
    if( employees !== false && employees.length > 0 ){
      emps = employees.map ( (emp,i) => {
        if ( emp.deleted === true ) return false;
          return (
            <li key={emp.id}>
              <span className={"link Employee"+emp.id} data-referensprops="fnamn,enamn" onClick={(e) => this.props.openWindow(e, "Employee", emp.id, ['fnamn','enamn'])}>{ emp.fnamn } { emp.enamn }</span>
            </li>
          );
      });
    }
    return (

      <ul className="list user-list">
        { emps.length > 0 && emps }
        <li className="separator separator-horizontal" />
        <li style={{ position: 'relative' }} onClick={(e) => this.newUser(e, object)}>
          <i className="fas fa-plus-square dropdown-selection-item-i text-success" />
          <span className="link dropdown-selection-item-span">{ lang['add_user'] }</span>
        </li>
      </ul>

    );
  }

  render(){

    let object = this.state.object;
    if( typeof object === 'undefined' || object === false || this.state.formAttributes.customergroupid.length === 0 ) return null;

    let attributes = this.props.prepareEditableAttributes(
      this.state.formAttributes,
      object
    );
    

    return (
      <div className="container">
        <div className="row">
          <div className="col-md-7">
            <ObjectForm
              objectId={ object.id }
              parent={this.props.parent}
              attributes={attributes}
              tabIndex={this.props.tabIndex}
              savePath={ api.Customer.save }
              object={object}
              onChange={this.onChange}
              updateState={this.props.updateState}
              module="Customer"
              onDelete={this.handleDelete}

            />
            
          </div>
          <div className="col-md-5 windowInfo">
            {
              this.renderInvoiceAddress( object )
            }
            {
              this.renderDeliveryAddresses( object )
            }
            <h5>{ lang['label_users'] }</h5>
            { this.renderEmployees( object ) }
            {
                object.isAffiliate > 0 &&
                <div className="card">
                <div className="card-body"> 
                    <h4>{ lang.label_generate_affiliate_code }</h4>
                    <div className="input-group mb-3">
                        <input type="text" className="form-control" value={ this.state.affCode ||''} />
                        <div className="input-group-append">
                            <button onClick={ () => this.generateCode() } className="btn btn-outline-primary" type="button" title={ lang.button_generate_affiliate_code }>
                                <i className="fa fa-random"> </i>
                            </button>
                        </div>
                        
                    </div>
                    <div className="alert alert-secondary">
                            {
                                this.state.object.affiliateCode.length > 0 ? (
                                    <>
                                        <div className="bold">{ lang.link_to_give_affiliate }</div>
                                        <div> { this.generatedLink() }</div>
                                    </>
                                ) :
                                (
                                    <div>{ lang.no_link_generated }</div>
                                )
                            }
                        </div>
                </div>
                </div>
            }
          </div>
        </div>
      </div>
    );
  }
};
