import {CustomerFormModule} from './modules/CustomerFormModule.jsx';
import {EmployeeFormModule} from './modules/EmployeeFormModule.jsx';
import {OrderFormModule} from './modules/OrderFormModule.jsx';
import {AdminFormModule} from './modules/AdminFormModule.jsx';
import {AdminModule} from './modules/AdminModule.jsx';
import {CustomerGroupFormModule} from './modules/CustomerGroupFormModule';
import {ProductionFormModule} from './modules/ProductionFormModule.jsx';
import {AddressFormModule} from './modules/AddressFormModule.jsx';
import {ArticleView} from './modules/views/articleView';
import {TemplateView} from './modules/views/TemplateView';
import {CustomerGroupView} from './modules/views/customerGroupView';
import {AccessoryView} from './modules/views/accessoryView';
import {StickerView} from './modules/views/stickerView';
import {OrderView} from './modules/views/orderView';
import {AddressView} from './modules/views/addressView';
import {QuoteView} from './modules/views/quoteView';
import {EmployeeView} from './modules/views/employeeView';
import {CustomerView} from './modules/views/customerView';
import {CustomerModule} from './modules/CustomerModule.jsx';
import {AddressModule} from './modules/AddressModule.jsx';
import {CustomerGroupModule} from './modules/CustomerGroupModule.jsx';
import {PricesModule} from './modules/PricesModule.jsx';
import {EmployeeModule} from './modules/EmployeeModule.jsx';
import {ArticleModule} from './modules/ArticleModule.jsx';
import {ArticleFormModule} from './modules/ArticleFormModule.jsx';
import {NewsModule} from './modules/NewsModule.jsx';
import {NewsFormModule} from './modules/NewsFormModule.jsx';
import {AccessoryModule} from './modules/AccessoryModule.jsx';
import {TemplateModule} from './modules/TemplateModule.jsx';
import {TemplateFormModule} from './modules/TemplateFormModule.jsx';
import {StickerModule} from './modules/StickerModule.jsx';
import {QuoteModule} from './modules/QuoteModule.jsx';
import {QuoteFormModule} from './modules/QuoteFormModule.jsx';
import {AccessoryFormModule} from './modules/AccessoryFormModule.jsx';
import {StickerFormModule} from './modules/StickerFormModule.jsx';
import {OrderModule} from './modules/OrderModule.jsx';
import formatMoney from 'accounting-js/lib/formatMoney.js';
import StatisticsModule from './modules/StatisticsModule.jsx';

export const moduleRouter = {
  '/'          : 'OrderModule',
  '/customers' : 'CustomerModule',
  '/employees' : 'EmployeeModule',
  '/orders'    : 'OrderModule',
  '/articles'  : 'ArticleModule',
  '/addresses' : "AddressModule",
  '/accessories' : "AccessoryModule",
  '/stickers'   : "StickerModule",
  '/customergroups' : "CustomerGroupModule",
  '/prices'     : 'PricesModule',
  '/quotes'     : 'QuoteModule',
  '/admins'     : 'AdminModule',
  '/templates'  : 'TemplateModule',
  '/stats'      : 'StatisticsModule'
}

export const modules = {
  CustomerModule: CustomerModule,
  EmployeeModule: EmployeeModule,
  OrderModule: OrderModule,
  ArticleModule: ArticleModule,
  AddressModule : AddressModule,
  AccessoryModule: AccessoryModule,
  TemplateModule: TemplateModule,
  StickerModule: StickerModule,
  PricesModule: PricesModule,
  QuoteModule: QuoteModule,
  CustomerGroupModule: CustomerGroupModule,
  NewsModule: NewsModule,
  AdminModule: AdminModule,
  StatisticsModule: StatisticsModule
};

export const viewModules = {
  ArticleView : ArticleView,
  OrderView : OrderView,
  CustomerView: CustomerView,
  EmployeeView: EmployeeView,
  AddressView: AddressView,
  AccessoryView: AccessoryView,  
  TemplateView: TemplateView, 
  StickerView: StickerView,
  CustomerGroupView: CustomerGroupView,
  QuoteView: QuoteView
}

export const windowModules = {
  CustomerFormModule: CustomerFormModule,
  EmployeeFormModule: EmployeeFormModule,
  OrderFormModule: OrderFormModule,
  AddressFormModule: AddressFormModule,
  ArticleFormModule: ArticleFormModule,
  AccessoryFormModule: AccessoryFormModule,
  StickerFormModule: StickerFormModule,
  ProductionFormModule: ProductionFormModule,
  CustomerGroupFormModule: CustomerGroupFormModule,
  QuoteFormModule:QuoteFormModule,
  NewsFormModule: NewsFormModule,
  AdminFormModule:AdminFormModule,
  TemplateFormModule:TemplateFormModule
};

export var accountingNumbers = {
  "se"  : function( value, symbol ){
    symbol = ( typeof symbol !== 'undefined' &&  symbol === true ? 'kr' : '' );
    let settings = {
      symbol: symbol,
      format: '%v %s',     // controls output: %s = symbol, %v = value (can be object, see docs)
      decimal: ',',       // decimal point separator
      thousand: ' ',      // thousands separator
      precision: 2,       // decimal places
      grouping: 3,        // digit grouping (not implemented yet)
      stripZeros: false,  // strip insignificant zeros from decimal part
      fallback: 0         // value returned on unformat() failure
    };

    return formatMoney( parseFloat( value ), settings );

  },
  "en"  : function ( value ){

    let settings = {
      symbol: '$',        // default currency symbol is '$'
      format: '%s%v',     // controls output: %s = symbol, %v = value (can be object, see docs)
      decimal: '.',       // decimal point separator
      thousand: ',',      // thousands separator
      precision: 2,       // decimal places
      grouping: 3,        // digit grouping (not implemented yet)
      stripZeros: false,  // strip insignificant zeros from decimal part
      fallback: 0         // value returned on unformat() failure
    };

    return formatMoney( parseFloat( value ), settings );
  }
}

export const objectStatuses = {
  "order_sent"          : "success",
  "order_in_production" : "info",
  "order_received"      : "primary",
  "order_active"        : "light",
  "order_canceled"      : "danger"

};

export const colorCodes = {
  'Aluminium'               : "#828282",
  'Blå'                     : "#0000FF",
  'Blåelox'			        : "#001b53",
  'Brun'	                : "#987E16",
  'Gul' 				  	: "#FFFF00",
  'Guld'			   		: "#DBC44C",
  "Grå" 			   		: "#aaaaaa",
  'Grön' 				    : "#00CC00",
  'Grönelox'				: "#004050",
  'Champagneelox'		    : "#EEE8AA",
  'Ljusblå' 				: "#59ACFF",
  'Ljusbrun' 				: "#EBDA7A",
  'Mässing'		 		    : "#D6C603",
  'Mörkblå'                 : "#001B53",
  'Mörkbrun'				: "#523c00",
  'Mörkgrön' 				: "#008040",
  'Mörkröd'                 : "#880000",
  'Naturelox'				: "#CCCCCC",
  'Orange' 			  	    : "#FF9900",
  'Rostfritt'				: "#CCCCCC",
  "Rosa"                    : "rgb(255, 192, 203)",
  'Rå'					    : "#ababab",
  'Röd' 					: "#FF0000",
  'Rödelox'			  	    : "#bf2a12",
  'Silver' 		   		    : "#F2F2F2",
  'Svart' 		  		    : "#000000",
  'Svartelox'				: "#111111",
  'Violett' 				: "#C900D9",
  'Vit' 			   		: "#FFFFFF",
  "Natur"			  		: "#CCCCCC",
  'Vit (Textur)'            : "#FFFFFF",
  'blue'                    : "#0000FF",
  'blueelox'			    : "#001b53",
  'brown'	                : "#987E16",
  'yellow' 				  	: "#FFFF00",
  'golf'			   		: "#DBC44C",
  "grey" 			   		: "#aaaaaa",
  'green' 				    : "#00CC00",
  'greenelox'				: "#004050",
  'champagneelox'		    : "#EEE8AA",
  'lightblue' 				: "#59ACFF",
  'lightbrown' 				: "#EBDA7A",
  'brass'		 		    : "#D6C603",
  'darkblue'                : "#001B53",
  'darkbrown'				: "#523c00",
  'darkgreen' 				: "#008040",
  'darkred'                 : "#880000",
  'natureelox'				: "#CCCCCC",
  'orange' 			  	    : "#FF9900",
  'stainless'				: "#CCCCCC",
  'raw'					    : "#ababab",
  'red' 					: "#FF0000",
  'redelox'			  	    : "#bf2a12",
  'silver' 		   		    : "#F2F2F2",
  'black' 		  		    : "#000000",
  'pink'                    : "#ffc0cb",
  'blackelox'				: "#111111",
  'violet' 				    : "#C900D9",
  'white' 			   		: "#FFFFFF",
  "nature"			  		: "#CCCCCC",
  'whitetexture'            : "#FFFFFF"
};




export const getRootDir = () => {
  const url = new URL(import.meta.url);
  return url.pathname;
};