import React from 'react';
import {windowManager} from '../windowManager';
import {api} from './api';
import {ObjectForm} from './ObjectForm.jsx';
import $ from 'jquery';
//import {Address} from './Address.jsx';
//import {ContentBlock} from './HtmlComponents.jsx';
import {addressFormAttributes} from './AddressModule';

//import lang from "@/lang/se.json";;

export class AddressFormModule extends React.Component{

  constructor( props ){
    super( props )

    this.state = {
      object : props.object
    };
    this.wm = new windowManager();
    this.updateWindowTitle = this.updateWindowTitle.bind( this );
    this.onChange = this.onChange.bind( this );
  }

  updateWindowTitle( e ){

    this.props.updateTitle( e.target.value );

  }

  onChange( object, changes ){
    this.setState({ object : object });
    this.props.onChange( object, changes );
  }
  handleDelete = () => {
    $('#'+this.props.windowId).find('.close').click();
  //  window.location.reload();
  }

  async fetchObject(){
    let self = this;
//    let url = api.api+api.Address.read+'/'+this.props.objectId;
//    let order = false;

    await api.send(
      'Address',
      'apiaddress/'+this.props.objectId,
      false,
      {
        type: 'GET',
        success : function( response ){

          let object = response.data;
          let time = new Date().getTime();
          self.setState({ isUpdated:  time, object: object });
      //    self.props.updateState();
          self.props.updateTitle( self.props.makeWindowTitle( object , ['name']) );

        }
      }
    );

  }

  async componentDidMount(){
    await this.fetchObject();

  }


  render(){

    let object = this.state.object;
    if( typeof object === 'undefined' || object === false ) return null;

    let attributes = this.props.prepareEditableAttributes( addressFormAttributes, object );

    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12">

            <ObjectForm
              objectId={ object.id }
              attributes={attributes}
              tabIndex={this.props.tabIndex}
              savePath={ api.Address.save }
              parent={this.props.parent}
              object={object}
              onChange={this.onChange}
              updateState={this.props.updateState}
              module="Address"
              onDelete={this.handleDelete}

            />
          </div>
        </div>
      </div>
    );
  }
};
