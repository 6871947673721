import React, { useState, useEffect, useRef } from 'react';
import { getFont } from '../config.js'


const align = {
    left: 'start',
    center: 'middle',
    right: 'end'
};

export default function TextBlock( props ){

    let { data, pixelConverter: px, editable, product: sign } = props;
    let x = data?.position?.left || data.width/2;
    let y = data?.position?.top || data.height/2;
    let width = data.width;
    let height = data.height;
    const fontName = data.active_font;
    const textAnchor = align[ data.horizontal_block_alignment ];
    const rows = data.rows;
    const font = getFont( fontName );
    const maximize = data.maximized;
    const textRef = useRef(null);

    if( maximize === true || maximize === "true" ){
        x = sign.margin_left;
        y = sign.margin_top;
        width = sign.width - sign.margin_left - sign.margin_right;
        height = sign.height - sign.margin_top - sign.margin_bottom;
    } 

    if( width === false ){
        width = sign.width - sign.margin_left - sign.margin_right;
    }

    const borders = {
        left: x,
        right: x + width,
        top: y,
        bottom: y + height
    };

    const justify = ( alignment, maxWidth ) => {
        let tX;
        const w = maxWidth ? maxWidth : width;
        switch( alignment ){
            case 'left':
                tX = 0;
                break;
            
            default:
            case 'center':
                tX = w / 2;
                break;
            
            case 'right':
                tX = w;

        }

        return tX;
    };



    const containsChars = ( string, chars ) => {
        return chars.test( string );
    }

    /**
     * CORRECT FONT TOP OFFSET
     */
    useEffect(() => {
        
        if (textRef.current) {
            
            const bbox = textRef.current.getBBox();
            const blockHeight = px.toPixel( height );
            const firstRow = { ...rows[0] };
            const topOffset = containsChars( firstRow.text, /[ÅÄÖåäö]/) ? firstRow.size*font.ascending_offset : firstRow.size*font.top_offset;
            const diff = ( blockHeight - bbox.height )/2;
            textRef.current.setAttribute( 'y', ( diff - topOffset ) );

        }
    }, [rows, height]);  // Include fontSizes in the dependency array

    /**
     * POSITION BLOCK AND ROW ALIGNMENTS
     */
    useEffect(() => {
        // check everytime if not maximized check longest row
        const pxWidth = px.toPixel( width );
        if( maximize !== true && maximize !== "true" ){

            const tnodes = textRef.current.childNodes;
            let longest = 0;
            let longestIndex = false;
                tnodes.forEach( (r,i) => {
                const bbox = r.getBBox();
                if( bbox.width > longest ){

                    longest = bbox.width;
                    if( longest > pxWidth )
                        longest = pxWidth;
                    longestIndex = i;
                }
                
                if( bbox.width > pxWidth ){
                    
                    r.setAttribute('textLength', pxWidth );
                    r.setAttribute('lengthAdjust', 'spacingAndGlyphs');
                }

            });

            width = longest;
            tnodes.forEach( (r,i) => {
                const bbox = r.getBBox();
                if( i !== longestIndex ){
                    const x = justify( data.rows[i].alignment, longest );
                    r.setAttribute('x' , x );

                        
                }
            })
        }
        
    }, [] )

    const calculateDy = (index) => {
        // Adjust dy based on the previous line's font size
        const prev = rows[ index - 1 ] || null;
        const row = rows[ index ];

        let prevOffset = 0;
        if( prev !== null ){
            prevOffset = prev.size*font.bottom_margin;    
        }
        
        let offset = (  prev?.sameRow === true && row?.sameRow === true  ) ? prevOffset : px.toPixel( parseFloat(row.size)+parseFloat(font.top_margin)*parseFloat(row.size) + parseFloat(prevOffset) );
       // console.log( row.text, ' prev ofset ', prevOffset, row.size, row.size*font.top_margin, ' dy ', offset  );
        return offset;
    };



    const renderRows = ( rows ) => {
        let dy;

        return (
            
            rows.length > 0 && 
            rows.map( (r,i) => {
                let th = px.toPixel( r.size * font.multiplier );
                let prev = rows[i-1] || null;
                let tSize = px.toPixel( parseFloat(r.size)*font.multiplier );
                
                return (
                    <tspan
                        key={i}
                        dy={calculateDy(i)}
                        fontSize={ `${th}px` }
                        lengthAdjust="spacingAndGlyphs"
                        textAnchor={ align[ r.alignment ] }
                        x={ `${px.toPixel( justify(  r.alignment ) )}px` }
                    >
                        { r.text }
                    </tspan>
                )
            })
        );
    };

    return (
        <g 
            transform={ `translate(${px.toPixel( x )},${ px.toPixel( y )} )`}
            onMouseEnter={ props?.editable === true ? () => hover(true) : null }
            onMouseLeave={ props?.editable === true ? () => hover(false) : null }
        >
        <text 
            
            ref={textRef}
            textAnchor={ textAnchor || 'middle' }
            x={ px.toPixel( x ) }
            style={{ fill: '#000', fontFamily: font.name, letterSpacing: font.letter_spacing, border: '1px solid black' }}
        >
            { renderRows( rows ) }
        </text>
        </g>
    );
 }