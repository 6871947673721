import { api } from '@/modules/api';
import React, { useEffect, useState } from 'react';
import {accountingNumbers} from '../../config';
import * as XLSX from 'xlsx/xlsx.mjs';
import DatePicker, { registerLocale } from 'react-datepicker';



function getStartAndEndDateOfMonth() {

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();

    const startDate = new Date(year, month, 1);
    const endDate = new Date(year, month + 1, 0);
  
    return {
      firstofMonth: startDate,
      lastofMonth: endDate
    };

}

export default function AffiliateReport({ lang }){

    const { firstofMonth, lastofMonth } = getStartAndEndDateOfMonth();
    const [ isLoaded, setLoaded ] = useState( false );
    const [ affiliates, setAffiliates ] = useState( [] );
    const [ startDate, setStartDate ] = useState( firstofMonth );
    const [ endDate, setEndDate ] = useState( lastofMonth );

    useEffect( () => {

        const fetchBootstrap = async ( ) => {

            await filterData( startDate, endDate );
            setLoaded( true );

        }
        fetchBootstrap();

    }, [] );

    const handleDatePicker = async ( e, key ) => {
        
        switch( key ){
            default:
            case 'startDate':
                setStartDate( e );
                break;
            case 'endDate':
                setEndDate( e ); 
        }
    }

    const filterData = async () => {
        
        const formattedStartDate = startDate.toISOString().split('T')[0];
        const formattedEndDate = endDate.toISOString().split('T')[0];
        const res = await api.send( '', 'company/referralReport', { from: formattedStartDate, to: formattedEndDate }, { type:'GET'} );
        setAffiliates( res.data );
        
    }

    const exportAllData = () => {
        const jsonData = prepareAllData();
        try {
            const ws = XLSX.utils.json_to_sheet(jsonData);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
            XLSX.writeFile(wb, 'data.xlsx');

        } catch (error) {
            console.error('Error exporting to Excel:', error);
        }
    }

    const prepareAllData = () => {
        let data = [];

        affiliates.forEach( a => {

            if( a?.referrals.length > 0 ){
                a.referrals.forEach( company => {
            
                    const subTotal = company?.sum?.subTotal || 0;
                    const totalPrice = company?.sum?.totalPrice || 0;
        
                    if( typeof company.sum !== 'undefined' ){
                        const item = {
                            affiliate: a.name,
                            custNr: company.custnr,
                            name: company.name,
                            subTotal: accountingNumbers.se( subTotal ),
                            total: accountingNumbers.se( totalPrice )
                        }
                        data.push( item );
            
                    }
                });
            }
               
        })
        
        return data;
    }

    const exportAffiliateData = ( affiliate ) => {

        const jsonData = prepareExportData( affiliate );

        try {
            const ws = XLSX.utils.json_to_sheet(jsonData);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
            XLSX.writeFile(wb, 'data.xlsx');

        } catch (error) {
            console.error('Error exporting to Excel:', error);
        }

    }

    const prepareExportData = ( affiliate ) => {

        let data = [];

        affiliate.referrals.forEach( company => {
            
            const subTotal = company?.sum?.subTotal || 0;
            const totalPrice = company?.sum?.totalPrice || 0;

            if( typeof company.sum !== 'undefined' ){
                const item = {
                    custNr: company.custnr,
                    name: company.name,
                    subTotal: accountingNumbers.se( subTotal ),
                    total: accountingNumbers.se( totalPrice )
                }
                data.push( item );
    
            }
        });

        return data;
    }

    const renderAffiliates = () => {
       
        return affiliates.map( (a,i) => {
            let totalSub = 0;
            let totalP = 0;
            return(
                <>
                <div className="card-body" key={i}>
                    
                    <div className="row">
                        <div className="col-md-4 col-lg-3">
                            <h5>{a.name}</h5>
                            <div className="p-1">#{ a.id } </div>
                            <div>{ lang.send_to }</div>
                            <div>{ a.email }</div>
                        </div>
                        <div className="col-md-8 col-lg-9">
                            <div className="d-flex justify-content-end">
                                <button className="btn btn-sm btn-success mb-1" onClick={ () => exportAffiliateData ( a ) }>
                                    <i className="fa fa-file-export"></i> { lang.label_export }
                                </button>
                            </div>
                            <div className="list-group">
                                {
                                    a?.referrals.length > 0 ? 
                                    (
                                        a.referrals.map( (r, j) => {
                                            
                                            totalSub += r?.sum?.subTotal || 0;
                                            totalP += r?.sum?.totalPrice || 0;

                                            if( typeof r.sum !== 'undefined' ) 
                                                return (
    
                                                    <div key={j} className="list-group-item d-flex align-items-center">
                                                        <div className="flex-fill">
                                                            <small className="bold">{ r.custnr } | #{ r.id }</small>
                                                            <div>{ r.name }</div>
                                                        </div>
                                                    
                                                        <div className="d-flex">
                                                            
                                                            <div className="pr-2 number">
                                                                <small className="bold">{ lang.label_price_sub_total }</small>
                                                                <div > { accountingNumbers.se( r.sum.subTotal ) }</div>
                                                            </div>
                                                            <div className="number" style={{ width: 70 }}>
                                                                <small className="bold">{ lang.label_price }</small>
                                                                <div>{ accountingNumbers.se(r.sum.totalPrice ) }</div>
                                                            </div>
                                                        </div>  
                                                    </div>
    
                                                );
    
                                        })
                                    ) :
                                    (
                                        <div className="list-group-item d-flex align-items-center">
                                            <div>{ lang.no_matching_records_found } </div>
                                        </div>
                                    )
                                    


                                }    
                                <div className="list-group-item list-group-item-secondary d-flex">
                                    <div className="flex-fill">
                                        <span className="bold">{ lang.label_sum }</span>
                                    </div>
                                    <div className="d-flex">
                                        <div className="pr-2 number">
                                            <small className="bold">{ lang.label_price_sub_total }</small>
                                            <div>{ accountingNumbers.se( totalSub ) }</div>
                                        </div>
                                        <div className="number" style={{ width: 70 }}>
                                            <small className="bold">{ lang.label_price }</small>
                                            <div>{ accountingNumbers.se( totalP ) }</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <hr/>
                </>
            );

  
        })
    }

    return (
        <div>
            {
                isLoaded === true ? (
                <>
                    <div className="row">
                        <div className="col-md-4">
                            <h2>{ lang.label_affiliate }</h2>
                        </div>
                        <div className="col-md-8">
                            <div className="alert alert-primary" role="alert">
                                <div className="d-flex align-items-center">
                                    <div className="pr-3">
                                        <i className="fa fa-2x fa-info-circle"></i>
                                    </div>
                                    <div>
                                        <div>Netto är subtotalen på alla orderrader innan rabatten är inkluderad</div>
                                        <div>Pris är med rabatten inkluderad</div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center">
                        

                            <DatePicker
                                id={"startDate"}
                                className={"datepicker form-control"}
                                selected={ startDate }
                                onSelect={(e) => handleDatePicker( e, 'startDate' ) }
                                name="startDate"
                                dateFormat="yyyy-MM-dd"
                            />
                            <DatePicker
                                id={"endDate"}
                                className={"datepicker form-control"}
                                selected={ endDate }
                                onSelect={(e) => handleDatePicker( e, 'endDate' ) }
                                name="endDate"
                                dateFormat="yyyy-MM-dd"
                            />
                            
                            <button className="btn btn-primary btn-sm" onClick={ () => filterData() }>{ lang.filter }</button>
                            
                               
                            <button className="btn btn-success btn-sm ml-2" onClick={ () => exportAllData() }>
                                <i className="fa fa-file-export"></i> { lang.label_export_all }
                            </button>

                        


                    </div>
                    <hr />
                                    
                    {   
                        affiliates.length > 0 &&
                        renderAffiliates()
                    }
                </>

        
                ) : (
                    <div>{ lang.label_loading } </div>
                )
            }
        </div>
    )
}