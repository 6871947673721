import React, { Component } from 'react';
//import $ from 'jquery';
import {DataList} from './DataList.jsx'
import {api} from './api';

import lang from "@/lang/se.json";


export const quoteFormAttributes = {
  "name"            : { "type" : "text", "label" : "label_name" },
  "contact"         : { "type" : "text", "label" : "label_contact" },
  "reference"       : { "type" : "text", "label" : "label_reference" },
  "description"     : { "type" : "textarea", "label" : "label_description" },
  "addon"           : { "type" : "number", "label" : "label_additional_payment" },
  "discount"      : { "type" : "number", "label" : "label_discount" },
  "amount"       : { "type" : "number", "label" : "label_quantity" },
  "type"  : { "type" : "select", "label" : "label_product_type" , "selection" : [] },
  "price"  : { "type" : "text", "label" : "label_price"  },
  "extra"  : { "type" : "number", "label" : "label_extra"  }

}

export const quoteFormParams = {
  module    : 'Quote',
  formModule: 'QuoteFormModule',
  view      : 'QuoteView',
  iconSrc   : api.Quote.iconSrc,
  api_query : api.Quote.read,
  titleColumns: ['name'],
  attributes : quoteFormAttributes
};

export class QuoteModule extends Component{

  constructor( props ){
    super(props);

    this.state = {
      tableProps    : {
        idColumn        : 'id',
        tableColumns  : ['id','name','contact','reference','description','price'],
        searchFields  : ['name','contact','reference','description'],
        sortColumn    : 1,
        sortDirection : 'asc',
        api_query     : api.Quote.dataList,
        start         : 0,
        length        : 100

      },
      name            : 'quotes',
      module_query  : window.location.pathname.substring(1).split('/'),

    };

  }

  openWindow = ( objectId, params ) => {

    if(typeof params !== 'undefined' ){
      params = { ...quoteFormParams, ...params,  orgWidth:700, orgHeight:800};
      this.props.openWindow( objectId, params );
    } else
      this.props.openWindow( objectId, quoteFormParams );
  }

  handleCreate = async (e) => {

    const response = await api.send( 'Quote', api.Quote.new );
    let params = { ...quoteFormParams, orgWidth:700, orgHeight:800 };
    this.props.openWindow( response.data.id, params );
  }

  render(){

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col" style={{ maxWidth:1200 }}>
            <div id="moduleContent">
              <h3> { lang[this.state.name] } </h3>
              <DataList
                tableProps={ this.state.tableProps }
                lang={lang}
                openWindow={this.openWindow}
                openinWindow={true}
                handleLogout={this.props.handleLogout}
                onCreate={this.handleCreate}
                prepareEditableAttributes={this.props.prepareEditableAttributes}
                formParams={quoteFormParams}
                onChange={this.props.onChange}
              />
            </div>
          </div>
        </div>
      </div>

    )

  }
}
