import React from 'react';
//import { api } from '../api';
//import lang from "@/lang/se.json";
export const QuoteView = (props) => {
//  const object = props.object;
//  const imageLink =  api.root+''+ object.imagelink;

  return (
    <div className="">
      <div>
        QUOTE
       </div>
    </div>

  );

};
