import React from 'react';
import {windowManager} from '../windowManager';
import {api} from './api';
import $ from 'jquery';
import {ObjectForm} from './ObjectForm.jsx';
//import {Address} from './Address.jsx';
//import {ContentBlock,DropdownSelection} from './HtmlComponents.jsx';
//import {Window} from '../window';
//import ComboSelect from 'react-combo-select';
//import {FileUpload} from './fileUpload';
import {customerGroupFormAttributes} from './CustomerGroupModule';
import 'react-combo-select/style.css';
import lang from "@/lang/se.json";

export class CustomerGroupFormModule extends React.Component{

  constructor( props ){
    super( props )

    this.state = {
      object : props.object,
      companies : []
    };

    this.wm = new windowManager();


  }

  updateWindowTitle = ( e ) => {

    this.props.updateTitle( e.target.value );

  }

  onChange = ( object, changes ) => {
    this.setState({ object : object });
    this.props.onChange( object, changes );
  }


  handleDelete = () => {
    $('#'+this.props.windowId).find('.close').click();
  }

  async componentDidMount(){

    if( typeof this.state.object === 'undefined' ){
      await this.fetchObject();

    }
//    const companies = await this.getCustomers();
//    this.props.makeWindowTitle( this.state.object , ['name'])
//    this.setState({ companies });

  }

  handleChangeCombo = async ( objId ) => {
    let {object} = this.state;
    const response = await api.send( "CustomerGroup", api.CustomerGroup.addToCompany + '/'+object.id, { foretagId: objId } );

    if( response.code === 200 ){
      object.companies.push( response.data );
      this.setState({ object });
      this.props.onChange( object , object.companies );
      this.props.updateState();
//      this.fetchOrder();
    }
  }



  async fetchObject(){
//    let self = this;
    const response = await api.send(
      'CustomerGroup',
      'customerGroup/'+this.props.objectId,
      { fetchCustomers: true },
      {
        type: 'GET'
      }
    );
    let object = response.data;
    this.setState({ object });
    this.props.updateTitle( this.props.makeWindowTitle( object , ['name']) );

  }

  renderCustomers( object )
  {
    let items = object.customers.map ( (item,i) => {
      if ( item.deleted === true ) return false;
        return (
          <li key={item.id}>
            <span className={"link Customer"+item.id} data-referensprops="name" onClick={(e) => this.props.openWindow(e, "Customer", item.id, ['name'])}>{ item.custnr } { item.name }</span>
          </li>
        )
    })
    return (

      <ul className="list user-list">
        {items}
      </ul>

    );
  }

  render( ){

    let object = {...this.state.object};
    if( typeof object === 'undefined' || object === false ||  typeof object.customers === 'undefined') return null;

    let attributes = this.props.prepareEditableAttributes(customerGroupFormAttributes, object );

    return (
      <div className="container">
        <div className="row">
          <div className="col-md-7">
            <ObjectForm
              objectId={ object.id }
              parent={this.props.parent}
              attributes={attributes}
              tabIndex={this.props.tabIndex}
              savePath={ api.CustomerGroup.save }
              object={object}
              onChange={this.onChange}
              updateState={this.props.updateState}
              module="CustomerGroup"
              onDelete={this.handleDelete}
            />
          </div>
          <div className="col-md-5 windowInfo">
            <h6 style={{ marginTop:20}}>{ lang['is_used_by'] }</h6>
            { object.customers.length > 0 && this.renderCustomers( object )}
          </div>
        </div>
      </div>
    );

  }
};
