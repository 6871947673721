import React, { Component } from 'react';
//import $ from 'jquery';
import {DataList} from './DataList.jsx'
import {api} from './api';

import lang from "@/lang/se.json";


export const newsFormAttributes = {
  "author"   : { "type" : "text", "label" : "label_author" },
  "text"     : { "type" : "textarea", "label" : "label_text", "height" : 235 },
  "title"    : { "type" : "text", "label" : "label_title" },
  "type"     : { "type" : "select", "label" : "label_news_type", "selection" : [
      {"label": "Information", "value" : "info" },
      {"label": "Bra att veta", "value" : "warning" },
      {"label": "Viktigt", "value" : "danger" },
      {"label": "Standard", "value" : "default" },
    ]
  },
  "columnSize"     : { "type" : "select", "label" : "label_size", "selection" : [
    { "label": "1/4 Kolumnbredd", "value" : "1" },
    { "label": "2/4 Kolumnbredd", "value" : "2" },
    { "label": "3/4 Kolumnbredd", "value" : "3" },
    { "label": "4/4 Kolumnbredd", "value" : "4" },
  ]
}
};

export const newsFormParams = {
  module    : 'News',
  formModule: 'NewsFormModule',
  view      : 'NewsView',
  iconSrc   : api.News.iconSrc,
  api_query : api.News.read,
  titleColumns: ['title'],
  attributes : newsFormAttributes
};

export class NewsModule extends Component{

  constructor( props ){
    super(props);

    this.state = {
      tableProps    : {
        idColumn        : 'tid',
        betweenColumn   : 'tid',
        tableColumns  : ['id','date','title','text','author'],
        searchFields  : ['author','title','text'],
        abbreviateCols: ['3'],
        maxChars      : 100,
        sortColumn    : 1,
        sortDirection : 'desc',
        itemsPerPage  : 100,
        api_query     : api.News.dataList,
        start         : 0,
        length        : 100

      },
      name            : 'news',
      module_query  : window.location.pathname.substring(1).split('/'),

    };

  }

  handleCreate = async (e) => {

    const response = await api.send( 'News', api.News.new );
    this.props.openWindow( response.data.id, newsFormParams );
  }

  openWindow = ( objectId ) => {
    let params = { ...newsFormParams, orgWidth: 500, orgHeight: 600  };
    this.props.openWindow( objectId, params );
  }

  render(){

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12" style={{ maxWidth:1200 }}>
            <div id="moduleContent">
              <h3> { lang[this.state.name] } </h3>
              <DataList
                onCreate={this.handleCreate}
                tableProps={ this.state.tableProps }
                openWindow={this.openWindow}
                prepareEditableAttributes={this.props.prepareEditableAttributes}
                formParams={newsFormParams}
                onChange={this.props.onChange}
                openinWindow={true}
              />
            </div>
          </div>
        </div>
      </div>

    )

  }
}
