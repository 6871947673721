import React, {Fragment} from 'react';
import {accountingNumbers} from '../config.js';
import DOMPurify from 'dompurify';
import $ from 'jquery';
import {api} from './api';
//import { renderSvg } from 'svg/renderSvg.js';
import store from '../store.js';

import lang from "@/lang/se.json";;
import { Buffer }  from 'buffer';

export class OrderItem extends React.Component{

  constructor( props ){

    super( props );

    this.state = {
      opened    : props.opened,
    }
    this.handleItemChange = this.handleItemChange.bind( this );
    this.handleInputClick = this.handleInputClick.bind( this );
  }
  _linebreak( string ){
    let fieldArr = string.split("<br/>");

    string = fieldArr.map( ( f,c ) => {
      if( f !== 'undefined' && f.length > 0 )
        return <div key={f+c}>{ f }</div>;
      return '';
    })

    return string;
  }
  _updateRow( rowId, updatedProperties ){

    let rows = this.props.rows;

    if( rows.length > 0 ){
      let i;
      for (i in rows ){
        let row = rows[i];
        if( row.id === rowId ){

          rows[i] = Object.assign({}, row, updatedProperties );

        }
      }
    }
    return rows;
  }
  _getRow( rowId ){

    let rows = this.props.rows;

    if( rows.length > 0 ){
      let i;
      for (i in rows ){
        let row = rows[i];
        if( row.id === rowId ){
          return row;
        }
      }
    }

    return false;
  }
  handleInputClick( e, domId ) {
      let element = $('#'+domId);

      if( $(element).attr('disabled') ){
        $(element).attr('disabled', false );
        $(e.target).removeClass('fa-unlock').addClass('fa-lock');
      }
      else {
        $(element).attr('disabled', true );
        $(e.target).removeClass('fa-lock').addClass('fa-unlock');
      }
  }
  handleClick( e ){

    this.setState({ opened: !this.state.opened });
    if( typeof this.props.handleClick !== 'undefined' )
      this.props.handleClick( e, !this.state.opened );

  }

  handleItemChange( e , property,  rowId ){
    let value = e.target.value;
    let row = { ...this._getRow( rowId ) };
    let object = JSON.parse(row.object);
    let self = this;
    row[property] = value;
    object[property] = value;

    api.send(
      'Order',
      'order/item/update/'+rowId,
      {
        item: JSON.stringify(object),
        properties : JSON.stringify({ property: property, data: value }),
        orderId: self.props.order.oid,
        userId: self.props.order.userId,
        rowId: rowId
      },
      {
        type: 'POST',
        success: function( json ){
          let rows = self._updateRow( rowId, row );
          self.props.onItemUpdate( rows );
        }
      }
    );

  }
  renderProductView(){
    let row = this.props.object;
    let item = JSON.parse(row.object);
    if( typeof item.blocks === 'undefined' )
      item.blocks = [];

    let entity = item.entity;
    let productView = '';
    let properties = '';
    let measurements = ( typeof item.diameter === 'undefined' ? item.width+'x'+item.height : item.diameter );
    let textrows = '';
    let rows = [];

    let viewWidth = $('#order-rows'+this.props.object.oid).width()/ 4 ;
    let b64 = null;
    switch ( entity ){

      case "Artikel":
        let trows = JSON.parse( item.textrows );
        rows = trows.map( ( tr, j ) => {
          return (<div key={j}>{ tr}</div> );
        });

        textrows = (
          <div>
            <div className="propLabel">{ lang['label_textrows'] }</div>
            {rows}
          </div>
        );

        properties = (
          <div className="row">
            <div className="col-md-6">
              <div><span className="propLabel">{ lang.article['objnamn'] }:</span><span className="m-r-10"> { item.objnamn }</span></div>
              <div><span className="propLabel">{ lang.article['objtitel'] }:</span><span className="m-r-10"> { item.objtitel }</span></div>
              <div><span className="propLabel">{ lang.article['info'] }: </span><span className="m-r-10" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(item.info)}} /></div>
            </div>
            <div className="col-md-6">
              {textrows}
            </div>
          </div>
        );


        productView = ( <img alt={"image_"+item.imagepath} src={api.root+item.imagepath} style={{ maxWidth: viewWidth }} /> );
        break;

      default:
      case "SogProduct":

        textrows = item.blocks.map( ( block, i ) => {
          let rows = '';
          if( typeof block.rows !== 'undefined' ){
            rows = block.rows.map( ( tr, j ) => {
              return (<tr key={j}><td>{ tr.size }</td><td>{ lang['label_'+tr.alignment] }</td><td dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(tr.text)}}></td></tr>);
            });
          }

          return (
            <div key={block.type+i}>
              <span className="propLabel">Block:</span> { block.name }
              <table className="table">
                <tbody>
                  <tr>
                    <td className="propLabel">{ lang['label_size']}</td>
                    <td className="propLabel">{ lang['label_alignment']}</td>
                    <td className="propLabel">{ lang['label_text']}</td>
                  </tr>
                  {rows}
                </tbody>
              </table>
            </div>
          );


        });

        productView =  (<iframe title="Scaled preview of product" style={{ border:'none', height:250, width:viewWidth }} src={api.api+'productView/'+row.producttype+'/'+row.id+"?maxWidth="+viewWidth+"&maxHeight=250&background=white" } />);
    //    let field3 = ( row.field3 !== null ? row.field3.split('<br/>').join(', ') : '');
    //console.log( item );
     //   productView = renderSvg( item, viewWidth, 200 )     
        properties = (
          <div className="row">
            <div className="col-md-6">
              <div><span className="propLabel">{ lang['label_material'] }:</span><span className="m-r-10"> { lang.materials[item.material] }</span></div>
              <div><span className="propLabel">{ lang['label_color'] }:</span><span className="m-r-10"> {item.color}</span></div>
              <div><span className="propLabel">{ lang['label_measurements'] }:</span><span className="m-r-10"> { measurements }mm</span></div>
              <div><span className="propLabel">{ lang['label_attach_method']}:</span><span className="m-r-10"> {  }</span></div>
            </div>
            <div className="col-md-6">
              {textrows}
            </div>
          </div>
        );

        break;
        case "TerminalMarking":
        case "terminalMarking":
            
            b64 = Buffer.from( JSON.stringify( item ) );
            b64 = b64.toString('base64');
            productView = ( <iframe src={`${api.root}designerV3/index.html#/fastView?product=${b64}&height=430px&width=300px`} style={{ width:'320px', height: '440px', border:'none', scroll:'hidden', overflow:'hidden' }} /> );
            properties = (
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div><b>Produkt</b>: {item.product_name}</div>
                        <div><b>Tillverkare</b>: {item.brand}</div>
                        <div><b>Tillverkarens artikelnummer</b>: {item.item_number}</div>
                    </div>
                </div>
            );
        break;

        case "signMarking":
            
            b64 = Buffer.from( JSON.stringify( item ) );
            b64 = b64.toString('base64');
            const width = 350;
            const ratio = item.width / item.height ;
            const height = item.entity === 'signMarking' ? item.height*ratio*4 : 500;
            productView = ( <iframe src={`${api.root}designerV3/index.html#/fastView?product=${b64}&height=${height}px&width=${width}px`} style={{ width: width, height: height, border:'none', scroll:'hidden', overflow:'hidden' }} /> );
            properties = (
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div><b>Produkt</b>: {item.product_name}</div>
                        <div><b>Tillverkare</b>: {item.brand}</div>
                        <div><b>Tillverkarens artikelnummer</b>: {item.item_number}</div>
                    </div>
                </div>
            );
        break;
        
      case "Tillbehor":
      case "ShopItem":
        properties = (
            <div className="row">
              <div className="col-md-6">
              <div><span className="propLabel">{ lang.article['objnamn'] }:</span><span className="m-r-10"> { item.name }</span></div>
              <div style={{ whiteSpace:'normal' }}><span className="propLabel">{ lang.label_description }: </span><span className="m-r-10" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(item.description)}} /></div>
            </div>
              <div className="col-md-6">
                {textrows}
              </div>
            </div>
          );
        productView = ( <img alt={"image_"+item.imagepath} src={api.root+item.imagepath} style={{ maxWidth: 200 }} /> );
        break;

    }


    return (
        <div className="orderProductInfo container-fluid">
          <div className="arrow"/>
          <div className="row">
            <div className="col-lg-4 col-md-5 col-sm-12">
              { productView }
            </div>
            <div className="col-lg-8 col-md-5 col-sm-12">
              { properties }
            </div>
          </div>
        </div>
    );

  }

  renderNonEditable( row ){
//    let order = this.props.order;

  //  let name = row.field1;
//    let product = JSON.parse(row.object);
    let field3 = '';
    let field4 = '';
    let textrows = row.textrows;
    let text = '';
    let obj = {};
    let name = '';
    let productError = false;
    if( typeof row.object === 'undefined' || row.object === null || row.object.length === 0 ){
      productError = true;
    }
    else{
      obj = JSON.parse( row.object );
      if( typeof obj !== 'undefined' && typeof obj.name !== 'undefined' )
        name = obj.name;
    }


    if( typeof name === 'undefined' || name === null || name.length === 0)
      name = lang[row.producttype];

    if( typeof row.field3 !== 'undefined' && row.field3 !== null  ){
      field3 = this._linebreak( row.field3 );
    }

    if( typeof textrows !== 'undefined' && textrows !== null && textrows.length > 0 ){
      text = textrows.map( (tr,j) => {
        return <div key={tr+j} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(tr)}}></div>;
      })
    }

    if( typeof row.field4 !== 'undefined' && row.field4 != null ) {
      field4 = this._linebreak( row.field4 );
    }
//    let inputStyle = { width: 60, marginRight: 5, height:30, padding:3 };
    let projectClass = '';
    if( row.projectId > 0 )
      projectClass = "project_"+row.projectId;

    return (
      <Fragment>
        <tr id={"row_"+row.id } className={ projectClass }>
          <td>
            { productError === false && <div className="prodname" onClick={(e) => this.handleClick(e, row) } data-rowid={row.id}><i className="fas fa-caret-down"></i> { name }</div> }
            {
              productError === true &&
              (
                <div> DATAFEL:<br/> Något har hänt med produkten när den lades till ordern. Datan är tom. <br/> Dubbelkolla med kunden om informationen som syns är korrekt. Passa på att hör hur de gick tillväga för att beställa produkten. <br/>
                <div>Datadump:<br/>
                [<br/>
                  productType: { row.entity },
                  aprice: { row.aprice },
                  quantity: {row.quantity},
                  total: {row.rowprice},
                  textrows: { JSON.stringify(row.textrows) }
                  <br/>
                ]

                </div>
                </div>
              )
            }

          </td>
          <td> { text }</td>
          <td>
            { row.field2 }
            { ( productError === false && obj.product_type === 'WireMarker' ) && <div>{ typeof obj.size_type !== 'undefined' && obj.size_type.substring(0,5) }</div> }
          </td>
          <td> { field3 }</td>
          <td>{ field4 }</td>
          <td className="number" style={{ whiteSpace:'nowrap'}}>
            { accountingNumbers.se(row.aprice) }
          </td>
          <td className="number" style={{ whiteSpace:'nowrap'}}>
            { row.quantity }
          </td>
          <td className="number">{ accountingNumbers.se( row.rowprice ) }</td>
        </tr>
        {
          this.state.opened === true &&
          <tr>
            <td colSpan="10"> 
                { this.renderProductView() }
            </td>
          </tr>
        }
      </Fragment>
    )
  }

  render(){
    let row = this.props.object;
    let order = this.props.order;
    

//    let product = JSON.parse(row.object);
    let field3 = '';
    let field4 = '';
    let textrows = row.textrows;
    let text = '';
    let obj = {};
    let name = '';
    let productError = false;
    if( typeof row.object === 'undefined' || row.object === null || row.object?.length === 0 ){
        productError = true;
    }
    else{
        obj = JSON.parse( row.object );
        name = obj?.name || obj?.product_name;
    }


    if( name?.length === 0)
      name = lang[row.producttype];

    if( typeof row.field3 !== 'undefined' && row.field3 !== null  ){
      field3 = this._linebreak( row.field3 );
    }

    if( typeof textrows !== 'undefined' && textrows !== null && textrows.length > 0 ){
      text = textrows.map( (tr,j) => {
        return <div key={tr+j}  dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(tr)}}></div>;
      })
    }

    if( typeof row.field4 !== 'undefined' && row.field4 != null ) {
      field4 = this._linebreak( row.field4 );
    }
    let inputStyle = { width: 60, marginRight: 5, height:30, padding:3 };
    let projectClass = '';
    if( row.projectId > 0 )
      projectClass = "project_"+row.projectId;

    if( typeof this.props.editable !== 'undefined' && this.props.editable === false )
      return this.renderNonEditable( row);


    return (
      <Fragment>
        <tr id={"row_"+row.id } className={ projectClass }>
          <td><input type="checkbox" checked={ row?.selected === true ? true:false } id={order.oid+"_"+row.id} data-rowid={row.id} onChange={ () => this.props.toggleSelect(row) } data-enity={ row.entity } data-producttype={row.producttype}/></td>
          <td></td>
          <td>
            { productError === false && <div className="prodname" onClick={(e) => this.handleClick(e, row) } data-rowid={row.id}><i className="fas fa-caret-down"></i> { name }</div> }
            {
              productError === true &&
              (
                <div> DATAFEL:<br/> Något har hänt med produkten när den lades till ordern. Datan är tom. <br/> Dubbelkolla med kunden om informationen som syns är korrekt. Passa på att hör hur de gick tillväga för att beställa produkten. <br/>
                <div>Datadump:<br/>
                [<br/>
                  productType: { row.entity },
                  aprice: { row.aprice },
                  quantity: {row.quantity},
                  total: {row.rowprice},
                  textrows: { JSON.stringify(row.textrows) }
                  <br/>
                ]

                </div>
                </div>
              )
            }
          </td>
          <td style={{ whiteSpace:'nowrap'}}>{text}</td>
          <td>
            { row.field2 }
            { ( productError === false &&  obj.product_type === 'WireMarker' ) && <div>{ typeof obj.size_type !== 'undefined' && obj.size_type.substring(0,5) }</div> }
          </td>
          <td> { field3 }</td>
          <td>{ field4 }</td>
          <td className="number" style={{ whiteSpace:'nowrap'}}>
            <input type="text" id={order.oid+'_aprice_'+row.id} style={inputStyle} disabled defaultValue={ row.aprice } onBlur={(e) => this.handleItemChange(e, 'aprice', row.id ) } />
            <i onClick={(e) => this.handleInputClick(e,order.oid+'_aprice_'+row.id)} title={ lang['toggle_enabled_and_disabled_field']} className="fas fa-unlock toggleDisable" />
          </td>
          <td className="number"  style={{ whiteSpace:'nowrap'}}>
            <input type="number" id={order.oid+'_qty_'+row.id}  style={inputStyle} disabled defaultValue={ row.quantity } onChange={(e) => this.handleItemChange(e, 'quantity', row.id ) } min="1"/>
            <i onClick={(e) => this.handleInputClick(e,order.oid+'_qty_'+row.id)} title={ lang['toggle_enabled_and_disabled_field']} className="fas fa-unlock toggleDisable" />
          </td>
          <td className="number">{ accountingNumbers.se( row.rowprice ) }</td>
        </tr>
        {
          this.state.opened === true &&
          <tr>
            <td colSpan="10"> { this.renderProductView() }</td>
          </tr>
        }
      </Fragment>
    )
  }

}
